import React from 'react';
import john from '../assets/john_circle.png';
import styled from 'styled-components'
import { OnlyMobile } from '../pages/home';
const yellow = "#F9CF19"
const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:stretch;
    position: absolute;
    bottom: -8vw;
    right: 10vw;
    @media (max-width: 600px){
        position: relative;
        right: 0;
        bottom: 0;
    }
`

const Title = styled.span`
    font-family: 'Roboto', sans-serif;
    font-size: 3.4vw;
    color: ${yellow};
    text-transform: uppercase;
    margin-bottom: 1vw;
    @media (max-width: 600px){
        font-size: 5vw;
        margin: 0.5em 0;
    }
`
const Subtitle = styled.span`
    font-family: 'Archivo Narrow', sans-serif;
    font-size: 2.1vw;
    color: #007486;
    letter-spacing: 0vw;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 0.3vw;
    @media (max-width: 600px){
        font-size: 1.5em;
        padding: 1vw 1vw 1vw 15vw;
    }
`

const Box = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2vw;
    background-color: white;
    width: 35vw;
    min-height: 12vw;
    position: relative;
    @media (max-width: 600px){
        width: 80%;
        // min-height: 60vw;
        transform: translateX(14vw);
        justify-content: flex-start;
        align-items: flex-start;
    }
`

const Text = styled.span`
    font-family: 'Roboto', sans-serif;
    line-height: 1.8vw;
    font-size: 1.5vw;
    margin-top: 0.3vw;
    @media (max-width: 600px){
        font-size: 4vw;
        line-height: 1.2em;
        padding: 1vw 1vw 1vw 15vw;
        margin-top: 0;
    }
`
const Italic = styled(Text)`
    font-style: italic;
    padding: 0;
`

const Image = styled.img`
    height: 30vw;
    width: 30vw;
    border-radius: 50%;
    object-fit: contain;
    position: absolute;
    top: 6%;
    left: -15vw;
`

export function Presenter(){
    return(
        <Container>
            <Title>About the presenter</Title>
            <Box>
                <OnlyMobile>
                    <Image src={john} alt="Small Image of John Bradshaw" />
                </OnlyMobile>
                <Subtitle>John Bradshaw</Subtitle>
                <Text>
                    Host John Bradshaw is the president of <Italic>It Is Written</Italic>,
                    an international Christian media ministry based in Chattanooga, Tennessee.  
                    He has held evangelistic series on six continents and recorded television programs
                    in thirty countries.
                </Text>
            </Box>

        </Container>
    )
}