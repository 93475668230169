
import { NetworkBase } from './Network';

export class DataManagement extends NetworkBase{
    
    get = async (path, shouldPersist = false, isCsv = false, retryCount = 0) => {
        return this._get(path)
    }

    put = async ( path, object, manager, shouldSync = true) => {
        
    }

    post = async ( path, body) => {
        return this._post(path, body)
    }

    delete = async (path, item, manager = null) => {

    }

    
}





