import React, { useEffect } from 'react';
import postscribe from 'postscribe'


export const Matomo = () => {
    useEffect(() => {
        if(window.location.host === "revelationtoday.com"){
            postscribe(
                "#matomo", 
                "<script> var _paq = window._paq = window._paq || []; _paq.push(['trackPageView']);  _paq.push(['enableLinkTracking']);(function() {var u=\"https://itiswritten.matomo.cloud/\";_paq.push(['setTrackerUrl', u+'matomo.php']);_paq.push(['setSiteId', '25']); var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];              g.async=true; g.src='//cdn.matomo.cloud/itiswritten.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);})();</script><noscript><p><img src=\"https://itiswritten.matomo.cloud/matomo.php?idsite=25&amp;rec=1\" style=\"border:0;\" alt=\"\" /></p></noscript>"
            )        
        } else if(window.location.host === 'greatresetchatt.com') {            
                postscribe("#matomo", "<script> var _paq = window._paq = window._paq || []; _paq.push(['trackPageView']);  _paq.push(['enableLinkTracking']);(function() {var u=\"https://itiswritten.matomo.cloud/\";_paq.push(['setTrackerUrl', u+'matomo.php']);_paq.push(['setSiteId', '24']); var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];              g.async=true; g.src='//cdn.matomo.cloud/itiswritten.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);})();</script><noscript><p><img src=\"https://itiswritten.matomo.cloud/matomo.php?idsite=24&amp;rec=1\" style=\"border:0;\" alt=\"\" /></p></noscript>")        
        }         
    },[])

    return(

    <div id="matomo"></div>)



}