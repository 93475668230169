import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import logo from '../assets/logo@2.png';
import titleImage from '../assets/mobiletitle.jpg';
import {HiOutlineMenuAlt3} from 'react-icons/hi';
import { PlayableContext } from './context';
import { useContext } from 'react';
import { ModalContext } from '../managers/ModalController';
import { useHistory, useLocation } from 'react-router';
import { useAuth } from '../managers/UserManager';
import {useAlert} from '../managers/Alert';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';



const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 2em;
    height: 100px;
    width: 90vw;
    @media (max-width: 600px){
        align-items: flex-start;
        height: auto;
        padding: 1vw;
    }
`
const Vertical = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    // padding: 3vw;
    background-color: white;

    @media (min-width: 601px) and (max-width: 1200px){
        padding: 0;
    }
    @media (max-width: 600px){
        padding: 2vw;
    }
`

const Left = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    flex: 3;
    max-width: min(40vw, 800px);
    z-index: 1;
    @media (max-width: 1090px){
        justify-content: flex-start;
    }
`
const Right = styled(Left)`
    justify-content: flex-end;
    align-items: center;
    flex: 1.5;
    flex-wrap:
    flex-shrink: 2;
    transform: translateY(10px);
    z-index: 0;
`
const Logo = styled.img`
    object-fit: contain;
    
    
    @media (min-width: 601px){
        max-width: min(250px, 15vw);
        min-width: 150px;
    }
    @media (max-width: 600px){
        padding: 0.3em;
        width: 23vw;
    }

`
const Title = styled.a`
    transition: all 0.2s ease-in-out;
    font-size: 1.3em;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 0;
    margin-left: min(2em, 2vw);
    marign-rigth: min(2em, 2vw);
    white-space: nowrap;
    @media (max-width: 1450px ){
        font-size: 1em;
        margin-left: min(1em, 1.5vw);
    }
    @media ( max-width: 1090px){
        display: none;
    }
`
const DropdownTitle = styled(Title)`
    margin-left: 0;
    font-size: 1.1em;
    @media (max-width: 1450px ){
        font-size: 0.8em;
        margin-left: min(1em, 1.5vw);
    }
    @media ( max-width: 1090px){
        display: none;
    }
`
const Italic = styled(Title)`
    font-style: italic;
`


const RNButton = styled.button`
    font-family: 'Roboto', sans-serif;
    transition: all 0.2s ease-in-out;
    background-color: #FFD41C;
    padding: 10px 25px;
    font-weight: bold;
    border: none;
    font-size: 1em;
    margin-left: min(2em, 2vw);
    border-radius: 4px;
    white-space: nowrap;
    @media (max-width: 1450px ){
        font-size: 0.7em;
        margin-left: min(1em, 1vw);
    }
    @media( max-width: 1090px){
        display: none;
    }
`
const MobileTitle = styled(Title)`
    @media ( max-width: 1090px){
        display: inherit;
        margin: min(3vw, 2vh);
        font-size: min(4vw, 6vh);
    }
    @media (max-height: 600px){
        margin-right: 1em;
        margin-left: 1em;
    }

`
const MobileRNButton = styled(RNButton)`
    @media( max-width: 1090px){
        color: black;
        display: inherit;
        font-size: 4vw;
        margin: 3vw;
    }
`

const Icon = styled(HiOutlineMenuAlt3)`
    font-size: 2em;
    @media (min-width: 1090px){
        display: none;
    }
`

const Sidebar = styled.div`
    position: fixed;
    overflow-y: scroll;
    top: 0;
    right: 0;
    width: 400px;
    height: 100vh;
    max-width: 60vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: flex-start;
    background-color: white;
    transition: all 0.3s ease-in-out;
    box-shadow: -20px 0px 40px rgba(0, 0, 0, 0.1);
    opacity: ${props => props.active ? 1 : 0};
    transform: ${props => props.active ? "translateX(0)" : "translateX(100%)"};

    @media (min-width: 1090px){
        transform: translateX(100%)
    }
`
const InnerMobileContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 100%;
    @media (max-height: 600px){
        flex-direction: row;
        height: auto;
    }
`

const Nav = styled.nav`
    position: fixed;
    z-index: 5;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Image = styled.img`
    display: block;
    max-width: 90vw;
    object-fit: contain;
    @media (min-width: 601px){
        display: none;
    }
`

export function Navbar({
    gotoWatch,
    gotoLearnMore,
    gotoSchedule,
    gotoSpecial,
    gotoLocation
}){

    const mobileNavRef = useRef()
    const [active, setActive] = useState(false)

    const close = () => setActive(false)
    const toggle = () => setActive(!active)
    useClickOutside(mobileNavRef, close)
    const {isLoggedIn, logout} = useAuth()

    const playable = useContext(PlayableContext)
    const history = useHistory()
    const {openModal, setDonateModal, openBibleQuestion} = useContext(ModalContext)
    const onSecureSpot = () => {
        shouldOpen()
    }
    const alert = useAlert()

    const onMobileTab = (func) => {
        close()
        func()
    }

    const gotoDonate = () => {
        setDonateModal()
        openModal()
    }

    const gotoHome = () => {
        history.push('/')
    }
    const gotoResources = () => {
        history.push('/resources')
      }   

    const shouldOpen = () => {
        if(isLoggedIn){
            alert.success("You're already registered")
        } else {
            openModal()
        }
    }


    return(
        <Nav>
            <Vertical>
                <Container>
                    <Left>
                        <Logo src={logo} alt="It Is Written Logo"  onClick={gotoHome}/>
                        {gotoLearnMore && <Title onClick={gotoLearnMore} title="Learn more about Revelation Today">Learn More</Title>}
                        {gotoSchedule&& <Title onClick={gotoSchedule} title="Revelation Today Schedule">Schedule</Title>}
                        {!!playable  && gotoWatch &&<Title onClick={gotoWatch} title="Watch Revelation Today Intro">Watch</Title>}
                        {playable && <Dropdown />}
                        <Title  onClick={gotoDonate} title="Donate to It Is Written">Donate</Title>
                        {!playable  && gotoSpecial && <Title onClick={gotoSpecial} title="Learn More about the Revleation Today Pre-series">Pre-Series</Title>}
                        {!playable  && gotoLocation && <Title onClick={gotoLocation} title="Location">Location</Title>}
                        <Title onClick={openBibleQuestion} title="Bible Question">Bible Question</Title>
                    </Left>
                    <Right>
                        {!isLoggedIn && <Italic>Registration is free!</Italic>}
                        {isLoggedIn && <Title onClick={logout} title="Logout from Revelation Today">Logout</Title>}
                        <RNButton onClick={onSecureSpot}>{isLoggedIn ? "I'M REGISTERED" : "REGISTER/LOGIN"}</RNButton>
                        <Icon onClick={toggle} />
                    </Right>
            </Container>
            <Image src={titleImage} />
         </Vertical>
        <Sidebar active={active} ref={mobileNavRef} >
        <MobileRNButton onClick={() => onMobileTab(onSecureSpot)} >{isLoggedIn ? "I'M REGISTERED" : "REGISTER NOW"}</MobileRNButton>
            <InnerMobileContainer>
                
                {gotoLearnMore && <MobileTitle  onClick={() => onMobileTab(gotoLearnMore)} title="Learn more about Revelation Today" >Learn More</MobileTitle>}
                {gotoSchedule && <MobileTitle   onClick={() => onMobileTab(gotoSchedule)} title="Revelation Today Schedule" >Schedule</MobileTitle>}
                {!playable  && gotoWatch && <MobileTitle  onClick={() => onMobileTab(gotoWatch)}  title="Watch Revelation Today Intro" >Watch</MobileTitle>}
                {!!playable  && <MobileTitle  onClick={() => onMobileTab(gotoResources)}  title="Study Guides and Free Downloads " >Study Guides &#38;<br /> Free Downloads</MobileTitle>}
                {!!playable  && <Link href="https://itiswritten.shop" target="_blank" ><MobileTitle title="Online Store" >Online Store</MobileTitle></Link>}
                <MobileTitle  onClick={() => onMobileTab(gotoDonate)} title="Donate to It Is Written">Donate</MobileTitle>
                {!playable  && gotoSpecial && <MobileTitle  onClick={() => onMobileTab(gotoSpecial)}  title="Learn More about the Revleation Today Pre-series" >Pre-Series</MobileTitle>}
                {!playable  && gotoLocation && <MobileTitle onClick={() => onMobileTab(gotoLocation)} title="Location">Location</MobileTitle>}
                <MobileTitle onClick={() => onMobileTab(openBibleQuestion)} title="Bible Question">Bible Question</MobileTitle>
                {isLoggedIn && <MobileTitle onClick={logout} title="Logout from Revelation Today">Logout</MobileTitle>}
            </InnerMobileContainer>
      </Sidebar>
      </Nav>
    )
}

export const useClickOutside = (ref, callback) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, callback]);
}


const Link = styled.a`
    color: black;
    text-decoration: none;
    text-highlight: none;
    &:visited {
        text-decoration: none;
    }
`

export default function Dropdown() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const history = useHistory()

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const gotoResources = () => {
    history.push('/resources')
  }   



  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
      <div>
        <Title
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          Resources
        </Title>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper style={{backgroundColor: "#fafafa"}}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem>
                        <DropdownTitle onClick={gotoResources}>Study Guides &#38;<br /> Free Downloads</DropdownTitle>
                    </MenuItem>
                    <MenuItem>
                        <Link href ="https://itiswritten.shop" target="_blank">
                            <DropdownTitle onClick={handleClose}>Online Store</DropdownTitle>
                        </Link>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>

  );
}