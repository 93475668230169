import React, {useRef} from 'react';
import { Parallax } from 'react-parallax';
import styled from 'styled-components';
import backgroundImage from '../assets/main.jpg'
import mobile_background from '../assets/mobile_background.jpg'
import titleImage from '../assets/title.png'
import { ParallaxBottom } from '../components/parallax-bottom';
import { Video } from '../components/video';
import { Chevrons} from '../components/chevrons'
import { Gifts } from '../components/gifts';
import { Special } from '../components/special';
import { Schedule } from '../components/schedule';
import { useContext } from 'react';
import { PlayableContext } from '../components/context';
import { Navbar } from '../components/navbar';
import { Map } from '../components/map';



export const Page = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`

export const OnlyMobile = styled.div`
    @media (min-width: 601px){
        display: none;
    }
`

export const OnlyDesktop = styled.div`
    @media (max-width: 600px){
        display: none;
    }
`
const TitleImage = styled.img`
    object-fit: contain;
    width: 85vw;
    margin-top: max(5vw, 50px);
    height: calc(85vw / 3.9493);
`
const MobileBanner = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 12vw;
    width: 100vw;
    background-color: #EB3A26;
    color: white;
    font-size: 1.6em;
    font-family: 'Archivo', sans-serif;
    font-weight: 700;
`

const MobileText = styled.span`
    color: white;
    font-style: italic;
    font-size: 1.1em;
    font-weight: 600;
    margin-top: 1.5em;
    font-family: 'Roboto', sans-serif;
`
const Center = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`
const Lower = styled.div`
    flex-direction: flex-start;
    align-items: flex-start;
    background: linear-gradient(#007486, #101624);

`
const H1 = styled.h1`
    visibility: 0;
    font-size: 0.1em;
`

const WatchButton = styled.button`
    font-size: 5vw;
    font-weight: bold;
    padding: 1vw 3vw;
    margin-top: 4vw;
    color: black;
    border: none;
    border-radius: 10px;
    background-color: #FFD41C;

`

export default function Home(){

    const watchRef = useRef()
    const learnMoreRef = useRef()
    const scheduleRef = useRef()
    const specialRef = useRef()
    const locationRef = useRef()

    const gotoWatch = () =>  watchRef.current?.scrollIntoView?.({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'

    })

    const gotoLocation = () =>  locationRef.current?.scrollIntoView?.({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'

    })
    const gotoLearnMore = () => learnMoreRef.current.scrollIntoView({
        behavior: 'smooth'
    })

    const gotoSchedule = () => scheduleRef.current.scrollIntoView({
        behavior: 'smooth'
    })

    const gotoSpecial = () => specialRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'

    })


    const playable = useContext(PlayableContext)
    return(

        <Page role="main">

            <Navbar
               gotoWatch={gotoWatch}
               gotoLearnMore={gotoLearnMore}
               gotoSchedule={gotoSchedule}
               gotoSpecial={gotoSpecial}
               gotoLocation={gotoLocation}
            />   
            <H1>
                Revelation Today
            </H1>
                         
            <OnlyDesktop >
                <Parallax        
                    // background image ratio 0.9687
                    // title image = 3.9493
                    style={{height: "calc(55vw / 0.9687)", width: "100vw", marginTop: 100}}
                    bgImage={backgroundImage}
                    bgImageAlt="Not What You Think" 
                    strength={700}>
                        <Center>
                            <TitleImage src={titleImage} alt="Title Image"/>
                        </Center>
                        
                </Parallax>
            </OnlyDesktop>
            <OnlyMobile >
                <Parallax        
                    // background image ratio 0.9687
                    // title image = 0.6428

                    style={{height: "calc(100vw / 0.8428)", width: "100%", paddingTop: "34vw"}}
                    bgImage={mobile_background}
                    bgImageAlt="Not What You Think" 
                    strength={100}>
                        <Center>
                            <MobileBanner >Starts October {playable ? 8 : 4}</MobileBanner>
                            <MobileText>Things aren't always as they appear</MobileText>
                            
                        </Center>
                        
                </Parallax>
            </OnlyMobile>
            
            <Lower>

                <ParallaxBottom 
                    ref={learnMoreRef}
                    gotoWatch={gotoWatch}
                    title={playable ? "Watch Here" : "LIVE in Chattanooga"}
                    subtitle={playable ? "Starting October 8" : "Starting October 4"}
                />
                <div ref={watchRef}></div>
                <Video  gotoWatch={gotoWatch} />
                {!playable && <Gifts />}
                
                <OnlyDesktop><Chevrons /></OnlyDesktop>
                {!playable && <Special ref={specialRef} />}
                <div ref={scheduleRef}></div>
                <Schedule  />
                {!playable && <Map ref={locationRef}/>}
            </Lower>
        </Page>

    )
}