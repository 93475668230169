import React from 'react';

export const PlayableContext = React.createContext();
export class Playable extends React.Component{
        playable;
        constructor(props){
            super(props)
            if(window.location.host === "revelationtoday.com") {
              this.playable = true
            } else {
                this.playable = false
            } 
        }

        render() {
            return(
                <PlayableContext.Provider value={this.playable}>
                    {this.props.children}
                </PlayableContext.Provider>
            )
        }
    
}