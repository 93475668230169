
import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)



const makeVideo = (id) => `https://us-central1-iiwtv-139f6.cloudfunctions.net/v1/videos/${id}`

const week1 = [
    {
        title: "A LOOK INTO THE FUTURE",
        subtitle: "The master plan for the days ahead.",
        datetime: dayjs("10/04/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        datetimert: dayjs("10/08/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        datetimert_end: dayjs("10/08/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hour'),
        rtTitle: "7:00 p.m.",
        epTitle: "7:00 p.m.",
        test_datetime: dayjs(),
        test_datetime_end: dayjs().add(10, 'second'),
        video_link: makeVideo(13092), 
        number: 1,
        lessons: [1],
        id: "87c681a3-b2b6-4d50-a9f3-85ae764498ab",
        groupLink: "",
        downloads: [{title : "Promises of Peace", link: "https://firebasestorage.googleapis.com/v0/b/studies-151020.appspot.com/o/Promises%20of%20Peace.pdf?alt=media&token=5505f5f3-63d2-48bd-a096-17552f9b6bd4"}],
        call_to_action: "grace"
    },

    {
        title: "THE UNSEEN ENEMY",
        subtitle: "Things are not always as they appear.",
        datetime: dayjs("10/05/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        datetimert: dayjs("10/09/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        datetimert_end: dayjs("10/09/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hour'),
        rtTitle: "7:00 p.m.",
        epTitle: "7:00 p.m.",
        test_datetime: dayjs().add(11, 'second'),
        test_datetime_end: dayjs().add(20, 'second'),
        video_link: makeVideo(13093), 
        number: 2,
        lessons: [2, 3],
        id: "429678b7-4b8f-420e-b858-c8fd984e2881",
        groupLink: "",
        downloads: [{title : "Challenge of the Sinful Heart", link: "https://firebasestorage.googleapis.com/v0/b/studies-151020.appspot.com/o/Evil%20-%20The%20Challenge%20of%20the%20Sinful%20Heart.pdf?alt=media&token=e703727d-9147-4f3f-9d63-25487326e661"}]
        
    },

    {
        title: "CURRENT THOUGHTS AND TRENDS",
        subtitle: "What happens if things don’t change?",
        datetime: dayjs("10/07/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),  
        datetimert: dayjs("10/10/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),  
        datetimert_end: dayjs("10/10/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19) .add(2, 'hour') ,
        rtTitle: "7:00 p.m.",
        epTitle: "7:00 p.m.",
        test_datetime: dayjs().add(21, 'second'),  
        test_datetime_end: dayjs().add(35, 'second'),
        video_link: makeVideo(13094), 
        number: 3,
        lessons: [4, 6],
        id: "3504ee61-b1b0-480a-a222-3770ed521052",
        groupLink: "",
        downloads: [{title: "Promises of Power", link: "https://firebasestorage.googleapis.com/v0/b/studies-151020.appspot.com/o/Promises%20of%20Power.pdf?alt=media&token=d045aa46-e3e6-4f2f-b4cc-03845c5bcb9e"}],
    },

    {
        title: "BALANCING THE SCALES OF JUSTICE",
        subtitle: "“And justice for all” becomes reality.",
        datetime: dayjs("10/08/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),  
        datetimert: dayjs("10/12/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),  
        datetimert_end: dayjs("10/12/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19) .add(2, 'hour') ,
        rtTitle: "7:00 p.m.",
        epTitle: "7:00 p.m.",
        test_datetime: dayjs("10/08/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),  
        test_datetime_end: dayjs("10/08/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hours') ,
        video_link: makeVideo(13095), 
        number: 4,
        lessons: [16],
        id: "40896963-0b29-4f05-88dd-e65556e1cfa3",
        downloads: []
    },

    {
        title: "PROPHECY AND PARDON",
        subtitle: "How does prophecy speak to our current age?",
        datetime: dayjs("10/09/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),  
        datetimert: dayjs("10/13/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),  
        datetimert_end: dayjs("10/13/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19) .add(2, 'hour') ,
        rtTitle: "7:00 p.m.",
        epTitle: "7:00 p.m.",
        test_datetime: dayjs("10/09/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),  
        test_datetime_end: dayjs("10/09/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hours') ,
        video_link: makeVideo(13096), 
        number: 5,
        lessons: [17],
        id: "919a442e-5c1b-4fb4-802c-d8dfd239dfd0",
        downloads: [],
        call_to_action: "truth"
    },

    {
        title: "THE GREAT RESET",
        subtitle: "The time is coming when everything will change.",
        datetime: dayjs("10/10/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),  
        datetimert: dayjs("10/15/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),  
        datetimert_end: dayjs("10/15/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19) .add(2, 'hour') ,
        rtTitle: "7:00 p.m.",
        epTitle: "7:00 p.m.",
        test_datetime: dayjs("10/10/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),  
        test_datetime_end: dayjs("10/10/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hours') ,
        video_link: makeVideo(13097), 
        number: 6,
        lessons: [13, 9],
        id: "de6f803d-1536-4656-bbb1-90571964d6cb",
        downloads: []
    }
]





const week2 = [
    {
        title: "THE DAY THAT CHANGES EVERYTHING",
        subtitle: "A defining issue confronting the world.",
        datetime: dayjs("10/12/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        datetimert: dayjs("10/16/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        datetimert_end: dayjs("10/16/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hour'),
        rtTitle: "7:00 p.m.",
        epTitle: "7:00 p.m.",
        test_datetime: dayjs("10/12/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        test_datetime_end: dayjs("10/12/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hours'),
        video_link: makeVideo(13098), 
        number: 7,
        lessons: [7],
        id: "bafc80a3-b2c8-4038-a357-250675a8bb4c",
        groupLink: "",
        downloads: [{title: "Remember", link: "https://firebasestorage.googleapis.com/v0/b/studies-151020.appspot.com/o/Remember.pdf?alt=media&token=69f69132-56be-4592-84b1-0d3840b823dc"}]
      
    },
    {
        title: "THE FIRST AMENDMENT",
        subtitle: "When history is rewritten, does anyone notice?",
        datetime: dayjs("10/13/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        datetimert: dayjs("10/19/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        datetimert_end: dayjs("10/19/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hour'),
        rtTitle: "7:00 p.m.",
        epTitle: "7:00 p.m.",
        test_datetime: dayjs("10/13/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        test_datetime_end: dayjs("10/13/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hours'),
        video_link: makeVideo(13100), 
        number: 8,
        lessons: [8],
        id: "b0725b2d-9736-4175-88c5-6aae8659e58c",
        groupLink: "",
        downloads: [{title: "God's Eternal Sign", link: "https://firebasestorage.googleapis.com/v0/b/hope-awakens.appspot.com/o/God%E2%80%99s%20Eternal%20Sign.pdf?alt=media&token=0c0b7506-1395-41e0-a7c2-f3ff32332aea"}],
        call_to_action: "renew"
      
    },
    {
        title: "CHARMED BY THE SUPERNATURAL",
        subtitle: "The growing influence of the occult.",
        datetime: dayjs("10/15/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        datetimert: dayjs("10/20/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        datetimert_end: dayjs("10/20/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hour'),
        test_datetime: dayjs("10/13/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        test_datetime_end: dayjs("10/13/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hours'),
        video_link: makeVideo(13101), 
        rtTitle: "7:00 p.m.",
        epTitle: "7:00 p.m.",
        number: 9,
        lessons: [10],
        id: "5aa7683c-942e-4281-8832-fc83c48c0151",
        groupLink: "",
        downloads:[ {title: "Coping with Grief", link: "https://firebasestorage.googleapis.com/v0/b/studies-151020.appspot.com/o/Coping%20With%20Grief.pdf?alt=media&token=4445eab7-5839-4862-a4c1-43c5a1b7ec34"}, {title: "Mystery of Death", link: "https://firebasestorage.googleapis.com/v0/b/studies-151020.appspot.com/o/The%20Mystery%20of%20Death.pdf?alt=media&token=e5e473eb-76a5-4036-9fdf-b93023e978d0"}]
      
    },
    {
        title: "RENEWABILITY, SUSTAINABILITY, AND ETERNITY",
        subtitle: "1,000 years that settle the earth’s issues.",
        datetime: dayjs("10/16/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 11),
        datetimert: dayjs("10/22/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        datetimert_end: dayjs("10/22/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hour'),
        rtTitle: "7:00 p.m.",
        epTitle: "7:00 p.m.",
        test_datetime: dayjs("10/16/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        test_datetime_end: dayjs("10/16/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hours'),
        video_link: makeVideo(13102), 
        number: 10,
        lessons: [11],
        id: "8f9d8f43-ee72-483e-ae7a-f88e91a8eff9",
        downloads: []
      
    },
    {
        title: "THE FINAL RESET",
        subtitle: "A planet restored, a people secured.",
        datetime: dayjs("10/16/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        datetimert: dayjs("10/23/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        datetimert_end: dayjs("10/23/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hour'),
        rtTitle: "7:00 p.m.",
        epTitle: "7:00 p.m.",
        test_datetime: dayjs("10/16/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        test_datetime_end: dayjs("10/16/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hours'),
        video_link: makeVideo(13103), 
        number: 11,
        lessons: [12],
        id: "beed5849-4d71-438c-997e-1f3417db282c",
        groupLink: "",
        downloads: [{title: "Understand Hell", link: "https://firebasestorage.googleapis.com/v0/b/studies-151020.appspot.com/o/Understanding%20Hell.pdf?alt=media&token=83e6f213-d7d7-4f4f-b87f-195b2313707c"}],
        call_to_action: "stand"
      
    }
]







const week3 = [

        {
            title: "OUT WITH THE OLD, IN WITH THE NEW",
            subtitle: "Discovering the best way to reset a life.",
            datetime: dayjs("10/19/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',19),
            datetimert: dayjs("10/26/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',19),
            datetimert_end: dayjs("10/26/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',19).add(2, 'hour'),
            rtTitle: "7:00 p.m.",
            epTitle: "7:00 p.m.",
            test_datetime: dayjs("10/19/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',19),
            test_datetime_end: dayjs("10/19/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',19).add(2, 'hours'),
            video_link: makeVideo(13104), 
            number: 12,
            lessons: [14],
            id: "4f50e2e3-a1a3-40b0-8293-b7ed1b3e78e5",
            downloads: []
      
        },
        {
            title: "THE Rx RESET ",
            subtitle: "Learning to take control of your destiny.",
            datetime: dayjs("10/20/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',19),
            datetimert: dayjs("10/27/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',19),
            datetimert_end: dayjs("10/27/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',19).add(2, 'hour'),
            rtTitle: "7:00 p.m.",
            epTitle: "7:00 p.m.",
            test_datetime: dayjs("10/20/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',19),
            test_datetime_end: dayjs("10/20/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',19).add(2, 'hours'),
            video_link: makeVideo(13105), 
            number: 13,
            lessons: [15],
            id: "7c076aa4-7820-4d89-859b-39eede469424",
            groupLink: "",
            downloads: [{title: "Health Care God's Way", link: "https://firebasestorage.googleapis.com/v0/b/studies-151020.appspot.com/o/Health%20Care%20God's%20Way.pdf?alt=media&token=57219737-596d-4da8-a43b-424eaa3893ff"}],
            call_to_action: "Jesus"
        },
        {
            title: "THE GLOBAL POWER SHIFT ",
            subtitle: "America and its place in the world.",
            datetime: dayjs("10/22/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',19),
            datetimert: dayjs("10/29/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',19),
            datetimert_end: dayjs("10/29/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',19).add(2, 'hour'),
            rtTitle: "7:00 p.m.",
            epTitle: "7:00 p.m.",
            test_datetime: dayjs("10/22/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',19),
            test_datetime_end: dayjs("10/22/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',19).add(2, 'hours'),
            video_link: makeVideo(13106), 
            number: 14,
            lessons: [],
            id: "a64a9064-bd96-44ff-88f7-1861a3d027da",
            groupLink: "",
            downloads: [{title: "When the Lion Roars", link: "https://firebasestorage.googleapis.com/v0/b/studies-151020.appspot.com/o/When%20the%20Lion%20Roars.pdf?alt=media&token=8e6fea27-bf6d-4aba-9f4c-30e9ac9d23f2"}]
        },
        {
            title: "PROPHECY’S POWER BROKERS",
            subtitle: "Influence, power, and strength in the end of time.",
            datetime: dayjs("10/23/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',11),
            datetimert: dayjs("10/30/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',11),
            datetimert_end: dayjs("10/30/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',11).add(2, 'hour'),
            rtTitle: "7:00 p.m.",
            epTitle: "7:00 p.m.",
            test_datetime: dayjs("10/23/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',11),
            test_datetime_end: dayjs("10/23/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',11).add(2, 'hours'),
            video_link: makeVideo(13107), 
            number: 15,
            lessons: [18],
            id: "bdc12877-8201-44f5-bfa8-1e459bb8a7b4",
            groupLink: "",
            downloads: [{title: "Revelation Today", link: "https://firebasestorage.googleapis.com/v0/b/studies-151020.appspot.com/o/Revelation%20Today.pdf?alt=media&token=e36fd89d-b3ea-4357-ae05-cf33e5c3929d"}]
        },
        {
            title: "THE MARK OF THE BEAST",
            subtitle: "Understanding the issues in earth’s last days.",
            datetime: dayjs("10/23/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',19),
            datetimert: dayjs("10/30/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',19),
            datetimert_end: dayjs("10/30/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',19).add(2, 'hour'),
            rtTitle: "7:00 p.m.",
            epTitle: "7:00 p.m.",
            test_datetime: dayjs("10/23/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',19),
            test_datetime_end: dayjs("10/23/2021", "MM/DD/YYYY").tz("America/New_York").set('hour',19).add(2, 'hours'),
            video_link: makeVideo(13108), 
            number: 16,
            lessons: [16, 20],
            id: "21c6d339-1e04-4182-a276-7a23817b2e44",
            downloads: []
        },


]


const week4 = [

    {
        title: "WHEN KINGDOMS FALL",
        subtitle: "Discerning the genuine from the false.",
        datetime: dayjs("10/26/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        datetimert: dayjs("11/02/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        datetimert_end: dayjs("11/02/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hour'),
        rtTitle: "7:00 p.m.",
        epTitle: "7:00 p.m.",
        test_datetime: dayjs("10/26/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        test_datetime_end: dayjs("10/26/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hours'),
        video_link: makeVideo(13109), 
        number: 17,
        lessons: [22],
        id: "6b91d2d7-7ef4-4207-a639-c3c5ceac6939",
        groupLink: "",
        downloads: [{title: "Babylon Rising", link: "https://firebasestorage.googleapis.com/v0/b/studies-151020.appspot.com/o/Babylon%20Rising%202021.pdf?alt=media&token=2a614604-cd92-46fe-b5e6-f23cac99dcb9"}]
    },
    {
        title: "FINDING LIGHT IN THE DARKNESS ",
        subtitle: "Navigating a confusing maze.",
        datetime: dayjs("10/27/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        datetimert: dayjs("11/03/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        datetimert_end: dayjs("11/03/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hour'),
        rtTitle: "7:00 p.m.",
        epTitle: "7:00 p.m.",
        test_datetime: dayjs().add(4, 'second'),//dayjs("09/27/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 13).tz("America/New_York").set('minute', 30),
        test_datetime_end: dayjs().add(4, 'second'),//dayjs("09/27/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 13).tz("America/New_York").set('minute', 30).add(2, 'hours'),
        video_link: makeVideo(13110), 
        number: 18,
        lessons: [21,23],
        id: "c08d3431-0232-4975-9fac-6e45bc6622f9",
        groupLink: "",
        downloads: [{title: "Steps to Christ", link: "https://firebasestorage.googleapis.com/v0/b/studies-151020.appspot.com/o/Steps%20to%20Christ.pdf?alt=media&token=5c0893ef-326b-4d63-8966-6d5ba1ca3b94"}]
    },
    {
        title: "STRENGTHENED FOR THE JOURNEY ",
        subtitle: "The gift that keeps on giving.",
        datetime: dayjs("10/29/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        datetimert: dayjs("11/05/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        datetimert_end: dayjs("11/05/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hour'),
        rtTitle: "7:00 p.m.",
        epTitle: "7:00 p.m.",
        test_datetime: dayjs("09/29/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        test_datetime_end: dayjs("09/29/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hours'),
        video_link: makeVideo(13111), 
        number: 19,
        lessons: [25, 24],
        id: "118bbb0a-7efd-416c-86a9-f1dc37f54fff",
        groupLink: "",
        downloads: [{title: "God will Provide", link: "https://firebasestorage.googleapis.com/v0/b/studies-151020.appspot.com/o/God%20Will%20Provide.pdf?alt=media&token=b622d32e-cab5-4167-a498-dfa8a3b2768b"}]
    },
    {
      title: "AS GOOD AS IT GETS",
      subtitle: "How true can it really be?",
      datetime: dayjs("10/30/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 11),
      datetimert: dayjs("11/06/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 11),
      datetimert_end: dayjs("11/06/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 11).add(2, 'hour'),

      test_datetime: dayjs("09/30/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 11),
      test_datetime_end: dayjs("09/30/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 11).add(2, 'hours'),
      rtTitle: "7:00 p.m.",
      epTitle: "7:00 p.m.",
      video_link: makeVideo(13113), 
      number: 20,
      lessons: [5],
      id: "74e231f3-320e-4351-9b57-3aa75889af4f",
      groupLink: "",
      downloads: [{title: "Great Controversy", link: "https://firebasestorage.googleapis.com/v0/b/studies-151020.appspot.com/o/Great%20Controversy%20IIW%20Text%20v%201%20b.pdf?alt=media&token=acf6f50e-2a6a-41a4-8ae5-f0f88ab22fd1"}]
    },
    {title: "THE MAN GOD TRIED TO KILL",
        subtitle: "",
        datetime: dayjs("10/30/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        datetimert: dayjs("11/06/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        datetimert_end: dayjs("11/06/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hour'),
        rtTitle: "7:00 p.m.",
        epTitle: "7:00 p.m.",
        test_datetime: dayjs("09/30/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19),
        test_datetime_end: dayjs("09/30/2021", "MM/DD/YYYY").tz("America/New_York").set('hour', 19).add(2, 'hours'),
        video_link: makeVideo(13112), 
        number: 21,
        lessons: [],
        id: "90e42208-4686-45cf-be20-83a0215c4a52",
        downloads: []
    },



]


const week_data = [week1, week2, week3, week4]
export default week_data

export const guides = [
    {
      id: 1,
      title: 'Can God Be Trusted?',
      unlocked: true,
      topic: "Bible Validity"
    },
    {
      id: 2,
      title: 'Seeing the Signs',
      topic: "Last Day Events"
    },
    {
      id: 3,
      title: 'Why does God Allow Suffering?',
      topic: "Suffering",
      alt: '../../assets/Images/alt3.jpg',
      },
    {
        id: 4,
        title: 'Hope for a Planet in Crisis',
        topic:"Sin Origins"
    },
    {
        id: 5,
        title: 'As Good as It Gets',  
        topic:"Heaven"
    },
    {
      id: 6,
      title: 'Peace On Earth',
      
        topic:"10 Commandments"
    },
    {
      id: 7,
      title: 'Quality Time',
      
        topic:"Sabbath"
    },
    {
      id: 8,
      title: 'The Day That Disappeared',
      
        topic:"Sabbath"
    },
    {
      id: 9,
      title: 'The War Is Over',
      
        topic:"Surrender"
    },
    {
      id: 10,
      title: 'The Mystery of Death',
      
        topic:"State of the Dead",
      alt: '../../assets/Images/alt10.jpg',
      add: 'How can I find hope and peace in a world of chaos? '
    },
    {
      id: 11,
      title: 'Revelation\'s 1,000 Years',
      
        topic:"Millennium"
    },
    {
      id: 12,
      title: 'Hellfire: Would God Really Do That?',
      
        topic:"Hell"
    },
    {
      id: 13,
      title: 'The Second Coming of Jesus',
      
        topic:"Second Coming"
    },
    {
      id: 14,
      title: 'Buried Alive?',
      
        topic:"Baptism"
    },
    {
      id: 15,
      title: 'Living Life to the Fullest!',
      
        topic:"Health",
      alt: '../../assets/Images/alt15.jpg',
      add: "What happens when I die? "
    },
    {
      id: 16,
      title: 'Rebuilding the Temple',
      
        topic:"Sanctuary"
    },
    {
      id: 17,
      title: 'The Messiah and the Judgment',
      
        topic:"Judgement"
    },
    {
      id: 18,
      title: 'The Mystery Beast of Revelation',
      
        topic:"Revelation 13"
    },
    {
      id: 19,
      title: 'The Mark of the Beast',
      
        topic:"Mark of the Beast",
      alt: '../../assets/Images/alt19.jpg',
      add: "The USA in Bible Prophecy"
    },
    {
      id: 20,
      title: 'The United States in Bible Prophecy',
      
        topic: "U.S. in Prophecy",
      alt: '../../assets/Images/alt20.jpg',
      add: "The Mark of the Beast"
    },
    {
      id: 21,
      title: 'In Search of the Church',
       
        topic:"Church"
    },
    {
      id: 22,
      title: 'The Fall of Babylon',
      
        topic:"Babylon"
    },
    {
      id: 23,
      title: 'Prophets and Prophecy',
      
        topic:"Prophets"
    },
    {
      id: 24,
      title: 'More Than Enough',
      
        topic:"Tithe"
    },
    {
      id: 25,
      title: 'The Gift of the Spirit',
      
        topic:"Holy Spirit"
    }
  ];
  