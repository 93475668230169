import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import Collapse from '@material-ui/core/Collapse'
import play_g from '../assets/play_grey.png';
import play_y from '../assets/play_yellow.png';
import video_image from '../assets/video.jpg';
import dayjs from 'dayjs';
import { useVideo } from './video';
import { useAuth } from '../managers/UserManager';
import { useModal } from '../managers/ModalController';
import { BibleStudyLink, ResourceLink } from '../pages/resources';
import {AiFillPlayCircle} from 'react-icons/ai'
import { RNButton } from '../pages/resources'
var isToday = require('dayjs/plugin/isToday');
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(isSameOrAfter)
dayjs.extend(isToday)
dayjs.extend(utc)
dayjs.extend(timezone)

const yellow = "#FFD41C"
const red = "#D7342C"
const grey = "#C4C4C3"
const grey_number = "#676768"

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 1;
    overflow-x: hidden;
    max-width: 90vw;
`

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 2vw;
`
const Icon = styled.img`
    width: 5vw;
    height: 5vw;
    object-fit: contain;
    margin: 1.4vw ;
    margin-left: 0;
    @media (max-width: 600px){
        display: none;
    }
`

const Number = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.1vw 0vw;
    width: 7vw;
    font-weight: bold;
    font-size: 6vw;
    letter-spacing: -2px;
    font-family: 'Archivo Narrow', sans-serif;
    color: ${props => props.color};
    background-color: ${props => props.backgroundColor};
    @media (max-width: 600px){
        font-size: 8vw;
        min-width: 10vw;
    }
`



const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 40vw;
    transform: all 0.3s ease-in-out;
    @media (max-width: 600px){
        width: 80vw;
    }
`

const Dateline = styled.div`
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.2vw 0 0.2vw 2vw;
    text-transform: uppercase;
    width: ${props => props.playable ? "50vw" : "60vw"};
    font-size: 2vw;
    background: linear-gradient(0.25turn, ${props => props.backgroundColor}, ${props => props.backgroundColor}, transparent);
    // background-color: ${props => props.backgroundColor};

    @media (max-width: 600px){
        background: ${props => props.backgroundColor};
        width:  80vw;
        font-size: 4vw;
        padding: 0.2vw 0 0.2vw 3vw;
    }
`
const Title = styled.span`
    font-size: 2vw;
    font-weight: bold;
    padding-left: 2vw;
    margin: 1vw 0 0.6vw 0;
    color: white;
  
    @media (max-width: 600px){
        font-size: 4vw;
        white-space: inherit;
        padding-left: 3vw;
    }
`

const Subtitle = styled.span`
    font-size: 1.4vw;
    color: white;
    padding-left: 2vw;
    white-space: nowrap;
    @media (max-width: 600px){
        font-size: 3.4vw;
        padding-left: 3vw;
        white-space: inherit;
    }
`



export const Link = styled.a`
    font-size: 1.5vw;
    margin: 1vw 0 0 0 ;
    white-space: nowrap;
    font-style: italic; 
    color: #ccc;
    padding-left: 2vw;
    cursor: pointer;
    @media (max-width: 600px){
        font-size: 3.5vw;
    }
`

const DateHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    transition: all 0.2s ease-in-out;
    opacity: ${props => props.active ? 1 : 0};
    z-index: -1;
    transform: translateY(${props => props.active ? '0vw' : '3vw'});
`

const DayOrNight = styled(Number)`
    transition: all 0.2s ease-in-out;
    background-color: grey;
    color: white;
    font-size: 1.3vw;
    // padding: 0.62vw 0;
    letter-spacing: 0;
    text-align: center;
    height: 2.5vw;
    // padding-right: ${props => props.number > 9 ? "1.1vw" : "0.1vw"};
    // padding-left: ${props => props.number > 9 ? "1.1vw" : "0.1vw"};
    margin-left: ${props => props.playable ? "6.4vw" : "0"}; 
    @media (max-width: 600px){
        padding-right: ${props => props.number > 9 ? "0.4vw" : "0.1vw"};
        padding-left: ${props => props.number > 9 ? "0.4vw" : "0.1vw"};
        font-size: 2.6vw;
        height: 6vw;
        margin-left: 0;
    }
`
const Time = styled(Dateline)`
    background: linear-gradient(0.25turn, #D7342C, #D7342C, transparent);
    color: white;
    height: 2.7vw;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: bold;
    @media (max-width: 600px){
        background:  #D7342C;
        height: 6.2vw;
    }
`
const Video = styled.img`
    object-fit: contain;
    width: 32vw;
    margin: 2vw 0;
    height: calc(32vw * 9 / 16);
    padding-left: 2vw;
    @media (max-width: 600px){
        width: 90%;
        height: calc(90% * 9 / 16);
    }
`


const PIcon = styled(AiFillPlayCircle)`
    color: red;
    opacity: 0.6;
    width: 10vw;
    height: 10vw;
    position: absolute;
    transform: translate(13vw, calc(17vw * 9 / 32));
    @media (max-width: 600px){
        width: 30vw;
        height: 30vw;
        transform: translate(24vw, calc(30vw * 9 / 32));
    }


`

const RNLink = styled.a`
    font-family: 'Roboto', sans-serif;
    transition: all 0.2s ease-in-out;
    background-color: #FFD41C;
    padding: 10px 25px;
    font-weight: bold;
    color: black;
    text-decoration: none;
    border: none;
    font-size: 1em;
    margin-right: min(2em, 2vw);
    border-radius: 4px;
    white-space: nowrap;
    @media (max-width: 1450px ){
        font-size: 0.7em;
        margin-right: min(1em, 1vw);
    }
`


export function Night({id, playable, number, date, video_link,  title, subtitle, lessons, call_to_action, downloads, groupLink}){


    const [unlocked, setUnlocked] = useState(false)
    const [active, setActive] = useState(false)
    const [video, setVid] = useState(null)
    const {setVideo} = useVideo()
    const {openModal} = useModal()
    const {isLoggedIn, markAttended} = useAuth()



    useEffect(() => {
        const call = async () => {
            let res = await fetch(video_link)
            let _video = await res.json()
            setVid(_video)
        }
        call()
    }, [])

    const toggle = () => {
        if(!unlocked){ return }
        if(!isLoggedIn) {
            openModal()
            return 
         }
        if(active){
            setActive(null)
        } else {
            setActive(number)
        }
    }


    const onVideoPlay = () => {
        if(isLoggedIn){
            markAttended(id)
            setVideo(video)
        } else {
            openModal()
        }

    }

  
    useEffect(() => {
        if(dayjs().isSameOrAfter(date)){
            if(!!isLoggedIn){
                setUnlocked(true)
                setActive(true)
            }
        
        } else {
            setActive(false)
        }


    }, [active, playable, date, isLoggedIn])

    const mark = () => {
        markAttended(id)
    }

    return(
        <Outer>
            <DateHeader active={true}>
                <DayOrNight number={number}  playable={false}>Episode</DayOrNight>
                <Time  onClick={toggle}> {date?.local().format("h:mm a")}</Time>
            </DateHeader >

            <Container>
                <Number 
                    onClick={toggle}
                    color={!playable ? red : unlocked ? red : grey_number} 
                    backgroundColor={!playable ? yellow : unlocked ? yellow : grey}
                >{number}</Number>
                <RightContainer>
                <Dateline  
                    onClick={toggle}
                    playable={playable}
                    backgroundColor={!playable ? yellow : unlocked ? yellow : grey}>
                        {date?.local().format("dddd, MMMM D")}
                </Dateline>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
                <Collapse in={active && playable}>
                    <RightContainer>
                        
                        <Video src={video?.thumbnail} alt="video" onClick={onVideoPlay}/>
                            <PIcon onClick={onVideoPlay}/>
                         <ResourceLink group groupTitle={"Free Gift PDF"} groupLink={groupLink} downloads={downloads}></ResourceLink>
                        <BibleStudyLink lessons={lessons} id={id}/>
                    </RightContainer>
                </Collapse>
                </RightContainer>
            </Container>
        </Outer>
    )
}