import dayjs from 'dayjs';
import React, { useState } from 'react';
import styled from 'styled-components';
import john from '../assets/john.png';
import { Link, Night } from './night';
import week_data from '../week_data';
import {first, last, flatMap} from 'lodash'
import { useEffect } from 'react';
import { Presenter } from './about-presenter';
import { Topics } from './continueing-topics';
import { useContext } from 'react';
import { PlayableContext } from './context';
import { useVideo } from './video';


const Outer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10vw;
    margin-bottom: 20vw;
    @media (max-width: 600px){
        margin-top: 0;
        margin-bottom: 1vw;
    }
`

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    padding-bottom: 10vw;
    background: linear-gradient(#003134, #02080A );
    min-height: 100vw;

    @media (max-width: 600px){
        background: none;
    }
`
const Inner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80vw;
    @media (max-width: 600px){
        width: 90vw;
    }

`
const color = "#FFD41C"
const Topbar = styled.div`
    padding-top: 5vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 2vw;

`
const Title = styled.h2`
    font-size: 2.5vw;
    color: ${color};
    letter-spacing: 0.3vw;
    text-transform: uppercase;
    margin-right: 0.5em;
    font-weight: 400;
    @media (max-width: 600px){
        font-size: 5vw;
    }
`

const Week_ = styled.div`
    transition: all 0.3s ease-in-out;
    font-size: 1.8vw;
    margin: 0 0 0 1vw;
    padding: 0.6vw 1vw;
    color: ${props => props.selected ? "white" : "#A5A5A5"};
    text-transform: uppercase;
    cursor: pointer;
    z-index: 3;
    border: ${props => props.selected ? `1px solid ${color}` : '1px solid transparent'};
    @media (max-width: 600px){
        font-size: 3.1vw;
    }
`
const Week = (props) => (<Week_ {...props}>Week {props.week}</Week_>)

const John = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    width: 40vw;
    height: calc( 40vw * 2.4140);
    transform: translate(3vw, -4vw);
    object-fit: contain;
    z-index: 2;
    @media (max-width: 600px){
        display: none;
    }
`

const Margin = styled.div`
    mmagin-left: 0;
    margin-bottom: 3em;
    
`


let timeout;
export function Schedule(){
    const [selectedWeek, setWeek] = useState(3)
    const [active, setActive] = useState(null)
    const [time, setTime] = useState(0)
    const [currentMeeting, setCurrentMeeting] = useState()
    const [nextMeeting, setNextMeeting] = useState()
    const playable = useContext(PlayableContext)
    const {onAutoSet} = useVideo()
    const getNextMeeting = () => {
        let now = dayjs()

        // check if we are way before

        const start = first(week_data[0])
        const end = last(week_data[3])
        if(now.isBefore(start.date)){
            // series hasn't started yet
            return start
        } else if(now.isAfter(end.date)){
            //series is over
            return null
        } else {
            // we are in the series
            // find the first night before now
            // and return the night before
            // return night - 1
            for(let night of flatMap(week_data)){
                if(now.isBefore(night?.datetimert)){
                    return night
                }
            }
        }
        return null
    }

    const getCurrent = () => {
        // get the first where now is after start time
        // and now is before end time
        let now = dayjs()
        let meetings = flatMap(week_data)

        let current = meetings.filter(m => now.isAfter(m.datetimert))
            .filter(m => now.isBefore(m.datetimert_end))

        // console.log(
        //     now,
        //     current[0]?.test_datetime, 
        //     current[0]?.test_datetime_end)
        // console.log(current)

        return current?.[0]
    }
    
 

    const setupNewCountdown = () => {
        clearTimeout(timeout)
        let night = getNextMeeting()
        setNextMeeting(night)
        if(night){
            // get seconds till start
            const now = dayjs()
            let seconds = night?.datetimert?.diff(now, 'second')
            
            setTime(time)
            startTimer(seconds)
        }
    }
    
 

    const startTimer = (time) => {
        timeout = setTimeout(
            () => {
                clearTimeout(timeout)
                if(time < 0){
                    setupNewCountdown()
                    return
                }
                // console.log(time)
                time -= 1
                startTimer(time)
            }, 
            1000
        )
    }

    useEffect(() => {
        // set a count down to next meeting
        if(playable){
            setupNewCountdown()
        }
        return () => clearTimeout(timeout)
    }, [])

    useEffect(() => {

        const _set = async () => {
            try{

            const current = getCurrent()
            let res = await fetch(current?.video_link)
            let _video = await res.json()
            onAutoSet(_video)
            }catch(e) {
                console.error(e)
            }
        }
        
        if(nextMeeting?.number - 1 >= 1){
            setActive(nextMeeting.number - 1)
            _set()
        }
    
    }, [nextMeeting])

    return(
        <Outer>
            <Container>
                <Inner>
                    <Topbar>
                        <Title>Schedule</Title>
                       { week_data.map((w, i) => {
                            const onClick = () => setWeek(i)
                            return <Week 
                                key={"week-" + i}
                                onClick={onClick}
                                week={i + 1}
                                selected={selectedWeek === i } 
                            />
                        })}
                    </Topbar>
                    {playable && <Margin >
                        <Link download href="https://www.itiswritten.com/wp-content/uploads/2021/10/PUBLIC-Schedule-OCT-8-20211005.pdf" target="_blank">Download Schedule </Link>
                    </Margin>}

          
                    {week_data[selectedWeek].map((night, i) => {
                    
                       return(<Night 
                            id={night?.id}
                            key={"night" + night?.number}
                            // active={night?.number == active}
                            playable={playable}
                            index={i} 
                            number={night?.number}
                            video_link={night?.video_link}
                            date={playable ? night?.datetimert : night?.datetime} 
                            // date={night?.test_datetime}
                            lessons={night?.lessons}
                            call_to_action={night?.call_to_action}
                            downloads={night?.downloads}
                            title={night?.title} 
                            subtitle={night?.subtitle}
                            groupLink={night?.groupLink}
                            // setActive={setActive}
                        />)
                    })}
                 <Topics />

                </Inner>
                <John src={john} alt="Image of John Bradshaw" />
                <Presenter />    
            </Container>
            
        </Outer>
    )
}

