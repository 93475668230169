
import {get, upperFirst, words, map, join, filter} from 'lodash';



export const BASE = 'https://us-central1-iiwtv-139f6.cloudfunctions.net/v1'
// export const BASE = 'http://localhost:5001/iiwtv-139f6/us-central1/v1'
// 


export const drawerWidth = 240;
export const circleSize = 300;
export const AlertTypes = {
  error: 'error',
  warning: 'warning',
  info: 'info',
  success: 'success',
  default: 'default'
}

export const AUTHENTICATION_STATUS = {
    authenticated: "Authenticated",
    unauthenticated: "Unathenticated",
    unknown: "Unknown"
}

export const validateOrg = (application, keys) => {
  return new Promise((resolve, reject) => {
      for(let key of keys){
          let item = get(application, `${key}`)
          if(key === 'zips'){
            let nonValid = filter(
                 item, 
                 zip => !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip) && !/^(?:[ABCEGHJ-NPRSTVXY]\d[A-Z][ -]?\d[A-Z]\d)$/i.test(zip) 
              )

              if(nonValid.length > 0){
                reject(`Invalid Zip/Postal Codes: ${join(nonValid, ' ')}`)
              }
              
          }
          else if( item === '' || item === null || item === undefined){
             let _words = words(key)
             _words = map(_words, word => upperFirst(word) + ' ')
             const name = join(_words, ' ')
             reject(name + ' Required')
          }
      }
      resolve()
  })
}


export const formatPhone = (number) => {
  // by maerics
  let cleaned = ('' + number).replace(/\D/g, '')
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
      let intlCode = (match[1] ? '+1 ' : '')
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return number
}


export const AlertPositions = {
  bottomCenter:  {
    vertical: 'bottom',
    horizontal: 'center',
  },
  bottomLeft: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  bottomRight: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  topRight: {
    vertical: 'top',
    horizontal: 'right',
  },
  topCenter: {
    vertical: 'top',
    horizontal: 'center',
  },
  topLeft: {
    vertical: 'top',
    horizontal: 'left',
  }
}



export const COUNTRIES = [
{value : "AF", text : "Afghanistan"},
{value : "AX", text : "Åland Islands"},
{value : "AL", text : "Albania"},
{value : "DZ", text : "Algeria"},
{value : "AS", text : "American Samoa"},
{value : "AD", text : "Andorra"},
{value : "AO", text : "Angola"},
{value : "AI", text : "Anguilla"},
{value : "AQ", text : "Antarctica"},
{value : "AG", text : "Antigua and Barbuda"},
{value : "AR", text : "Argentina"},
{value : "AM", text : "Armenia"},
{value : "AW", text : "Aruba"},
{value : "AU", text : "Australia"},
{value : "AT", text : "Austria"},
{value : "AZ", text : "Azerbaijan"},
{value : "BS", text : "Bahamas"},
{value : "BH", text : "Bahrain"},
{value : "BD", text : "Bangladesh"},
{value : "BB", text : "Barbados"},
{value : "BY", text : "Belarus"},
{value : "BE", text : "Belgium"},
{value : "BZ", text : "Belize"},
{value : "BJ", text : "Benin"},
{value : "BM", text : "Bermuda"},
{value : "BT", text : "Bhutan"},
{value : "BO", text : "Bolivia"},
{value : "BQ", text : "Bonaire, Sint Eustatius and Saba"},
{value : "BA", text : "Bosnia and Herzegovina"},
{value : "BW", text : "Botswana"},
{value : "BV", text : "Bouvet Island"},
{value : "BR", text : "Brazil"},
{value : "IO", text : "British Indian Ocean Territory"},
{value : "BN", text : "Brunei Darussalam"},
{value : "BG", text : "Bulgaria"},
{value : "BF", text : "Burkina Faso"},
{value : "BI", text : "Burundi"},
{value : "CV", text : "Cabo Verde"},
{value : "KH", text : "Cambodia"},
{value : "CM", text : "Cameroon"},
{value : "CA", text : "Canada"},
{value : "KY", text : "Cayman Islands"},
{value : "CF", text : "Central African Republic"},
{value : "TD", text : "Chad"},
{value : "CL", text : "Chile"},
{value : "CN", text : "China"},
{value : "CX", text : "Christmas Island"},
{value : "CC", text : "Cocos (Keeling) Islands"},
{value : "CO", text : "Colombia"},
{value : "KM", text : "Comoros"},
{value : "CG", text : "Congo"},
{value : "CD", text : "Congo, The Democratic Republic of the"},
{value : "CK", text : "Cook Islands"},
{value : "CR", text : "Costa Rica"},
{value : "CI", text : "Côte d'Ivoire"},
{value : "HR", text : "Croatia"},
{value : "CU", text : "Cuba"},
{value : "CW", text : "Curaçao"},
{value : "CY", text : "Cyprus"},
{value : "CZ", text : "Czechia"},
{value : "DK", text : "Denmark"},
{value : "DJ", text : "Djibouti"},
{value : "DM", text : "Dominica"},
{value : "DO", text : "Dominican Republic"},
{value : "EC", text : "Ecuador"},
{value : "EG", text : "Egypt"},
{value : "SV", text : "El Salvador"},
{value : "GQ", text : "Equatorial Guinea"},
{value : "ER", text : "Eritrea"},
{value : "EE", text : "Estonia"},
{value : "SZ", text : "Eswatini"},
{value : "ET", text : "Ethiopia"},
{value : "FK", text : "Falkland Islands (Malvinas)"},
{value : "FO", text : "Faroe Islands"},
{value : "FJ", text : "Fiji"},
{value : "FI", text : "Finland"},
{value : "FR", text : "France"},
{value : "GF", text : "French Guiana"},
{value : "PF", text : "French Polynesia"},
{value : "TF", text : "French Southern Territories"},
{value : "GA", text : "Gabon"},
{value : "GM", text : "Gambia"},
{value : "GE", text : "Georgia"},
{value : "DE", text : "Germany"},
{value : "GH", text : "Ghana"},
{value : "GI", text : "Gibraltar"},
{value : "GR", text : "Greece"},
{value : "GL", text : "Greenland"},
{value : "GD", text : "Grenada"},
{value : "GP", text : "Guadeloupe"},
{value : "GU", text : "Guam"},
{value : "GT", text : "Guatemala"},
{value : "GG", text : "Guernsey"},
{value : "GN", text : "Guinea"},
{value : "GW", text : "Guinea-Bissau"},
{value : "GY", text : "Guyana"},
{value : "HT", text : "Haiti"},
{value : "HM", text : "Heard Island and McDonald Islands"},
{value : "VA", text : "Holy See (Vatican City State)"},
{value : "HN", text : "Honduras"},
{value : "HK", text : "Hong Kong"},
{value : "HU", text : "Hungary"},
{value : "IS", text : "Iceland"},
{value : "IN", text : "India"},
{value : "ID", text : "Indonesia"},
{value : "IR", text : "Iran, Islamic Republic of"},
{value : "IQ", text : "Iraq"},
{value : "IE", text : "Ireland"},
{value : "IM", text : "Isle of Man"},
{value : "IL", text : "Israel"},
{value : "IT", text : "Italy"},
{value : "JM", text : "Jamaica"},
{value : "JP", text : "Japan"},
{value : "JE", text : "Jersey"},
{value : "JO", text : "Jordan"},
{value : "KZ", text : "Kazakhstan"},
{value : "KE", text : "Kenya"},
{value : "KI", text : "Kiribati"},
{value : "KP", text : "Korea, Democratic People's Republic of"},
{value : "KR", text : "Korea, Republic of"},
{value : "KW", text : "Kuwait"},
{value : "KG", text : "Kyrgyzstan"},
{value : "LA", text : "Lao People's Democratic Republic"},
{value : "LV", text : "Latvia"},
{value : "LB", text : "Lebanon"},
{value : "LS", text : "Lesotho"},
{value : "LR", text : "Liberia"},
{value : "LY", text : "Libya"},
{value : "LI", text : "Liechtenstein"},
{value : "LT", text : "Lithuania"},
{value : "LU", text : "Luxembourg"},
{value : "MO", text : "Macao"},
{value : "MG", text : "Madagascar"},
{value : "MW", text : "Malawi"},
{value : "MY", text : "Malaysia"},
{value : "MV", text : "Maldives"},
{value : "ML", text : "Mali"},
{value : "MT", text : "Malta"},
{value : "MH", text : "Marshall Islands"},
{value : "MQ", text : "Martinique"},
{value : "MR", text : "Mauritania"},
{value : "MU", text : "Mauritius"},
{value : "YT", text : "Mayotte"},
{value : "MX", text : "Mexico"},
{value : "FM", text : "Micronesia, Federated States of"},
{value : "MD", text : "Moldova"},
{value : "MC", text : "Monaco"},
{value : "MN", text : "Mongolia"},
{value : "ME", text : "Montenegro"},
{value : "MS", text : "Montserrat"},
{value : "MA", text : "Morocco"},
{value : "MZ", text : "Mozambique"},
{value : "MM", text : "Myanmar"},
{value : "NA", text : "Namibia"},
{value : "NR", text : "Nauru"},
{value : "NP", text : "Nepal"},
{value : "NL", text : "Netherlands"},
{value : "NC", text : "New Caledonia"},
{value : "NZ", text : "New Zealand"},
{value : "NI", text : "Nicaragua"},
{value : "NE", text : "Niger"},
{value : "NG", text : "Nigeria"},
{value : "NU", text : "Niue"},
{value : "NF", text : "Norfolk Island"},
{value : "MK", text : "North Macedonia"},
{value : "MP", text : "Northern Mariana Islands"},
{value : "NO", text : "Norway"},
{value : "OM", text : "Oman"},
{value : "PK", text : "Pakistan"},
{value : "PW", text : "Palau"},
{value : "PS", text : "Palestine, State of"},
{value : "PA", text : "Panama"},
{value : "PG", text : "Papua New Guinea"},
{value : "PY", text : "Paraguay"},
{value : "PE", text : "Peru"},
{value : "PH", text : "Philippines"},
{value : "PN", text : "Pitcairn"},
{value : "PL", text : "Poland"},
{value : "PT", text : "Portugal"},
{value : "PR", text : "Puerto Rico"},
{value : "QA", text : "Qatar"},
{value : "RE", text : "Réunion"},
{value : "RO", text : "Romania"},
{value : "RU", text : "Russian Federation"},
{value : "RW", text : "Rwanda"},
{value : "BL", text : "Saint Barthélemy"},
{value : "SH", text : "Saint Helena, Ascension and Tristan da Cunha"},
{value : "KN", text : "Saint Kitts and Nevis"},
{value : "LC", text : "Saint Lucia"},
{value : "MF", text : "Saint Martin (French part)"},
{value : "PM", text : "Saint Pierre and Miquelon"},
{value : "VC", text : "Saint Vincent and the Grenadines"},
{value : "WS", text : "Samoa"},
{value : "SM", text : "San Marino"},
{value : "ST", text : "Sao Tome and Principe"},
{value : "SA", text : "Saudi Arabia"},
{value : "SN", text : "Senegal"},
{value : "RS", text : "Serbia"},
{value : "SC", text : "Seychelles"},
{value : "SL", text : "Sierra Leone"},
{value : "SG", text : "Singapore"},
{value : "SX", text : "Sint Maarten (Dutch part)"},
{value : "SK", text : "Slovakia"},
{value : "SI", text : "Slovenia"},
{value : "SB", text : "Solomon Islands"},
{value : "SO", text : "Somalia"},
{value : "ZA", text : "South Africa"},
{value : "GS", text : "South Georgia and the South Sandwich Islands"},
{value : "SS", text : "South Sudan"},
{value : "ES", text : "Spain"},
{value : "LK", text : "Sri Lanka"},
{value : "SD", text : "Sudan"},
{value : "SR", text : "Suriname"},
{value : "SJ", text : "Svalbard and Jan Mayen"},
{value : "SE", text : "Sweden"},
{value : "CH", text : "Switzerland"},
{value : "SY", text : "Syrian Arab Republic"},
{value : "TW", text : "Taiwan"},
{value : "TJ", text : "Tajikistan"},
{value : "TZ", text : "Tanzania"},
{value : "TH", text : "Thailand"},
{value : "TL", text : "Timor-Leste"},
{value : "TG", text : "Togo"},
{value : "TK", text : "Tokelau"},
{value : "TO", text : "Tonga"},
{value : "TT", text : "Trinidad and Tobago"},
{value : "TN", text : "Tunisia"},
{value : "TR", text : "Turkey"},
{value : "TM", text : "Turkmenistan"},
{value : "TC", text : "Turks and Caicos Islands"},
{value : "TV", text : "Tuvalu"},
{value : "UG", text : "Uganda"},
{value : "UA", text : "Ukraine"},
{value : "AE", text : "United Arab Emirates"},
{value : "GB", text : "United Kingdom"},
{value : "UM", text : "United States Minor Outlying Islands"},
{value : "US", text : "United States"},
{value : "UY", text : "Uruguay"},
{value : "UZ", text : "Uzbekistan"},
{value : "VU", text : "Vanuatu"},
{value : "VE", text : "Venezuela"},
{value : "VN", text : "Vietnam"},
{value : "VG", text : "Virgin Islands, British"},
{value : "VI", text : "Virgin Islands, U.S."},
{value : "WF", text : "Wallis and Futuna"},
{value : "EH", text : "Western Sahara"},
{value : "YE", text : "Yemen"},
{value : "ZM", text : "Zambia"},
{value : "ZW", text : "Zimbabwe"},
]

export const STATES = [
  {
      "name": "Alabama",
      "abbreviation": "AL"
  },
  {
      "name": "Alaska",
      "abbreviation": "AK"
  },
  {
      "name": "American Samoa",
      "abbreviation": "AS"
  },
  {
      "name": "Arizona",
      "abbreviation": "AZ"
  },
  {
      "name": "Arkansas",
      "abbreviation": "AR"
  },
  {
      "name": "California",
      "abbreviation": "CA"
  },
  {
      "name": "Colorado",
      "abbreviation": "CO"
  },
  {
      "name": "Connecticut",
      "abbreviation": "CT"
  },
  {
      "name": "Delaware",
      "abbreviation": "DE"
  },
  {
      "name": "District Of Columbia",
      "abbreviation": "DC"
  },
  {
      "name": "Federated States Of Micronesia",
      "abbreviation": "FM"
  },
  {
      "name": "Florida",
      "abbreviation": "FL"
  },
  {
      "name": "Georgia",
      "abbreviation": "GA"
  },
  {
      "name": "Guam",
      "abbreviation": "GU"
  },
  {
      "name": "Hawaii",
      "abbreviation": "HI"
  },
  {
      "name": "Idaho",
      "abbreviation": "ID"
  },
  {
      "name": "Illinois",
      "abbreviation": "IL"
  },
  {
      "name": "Indiana",
      "abbreviation": "IN"
  },
  {
      "name": "Iowa",
      "abbreviation": "IA"
  },
  {
      "name": "Kansas",
      "abbreviation": "KS"
  },
  {
      "name": "Kentucky",
      "abbreviation": "KY"
  },
  {
      "name": "Louisiana",
      "abbreviation": "LA"
  },
  {
      "name": "Maine",
      "abbreviation": "ME"
  },
  {
      "name": "Marshall Islands",
      "abbreviation": "MH"
  },
  {
      "name": "Maryland",
      "abbreviation": "MD"
  },
  {
      "name": "Massachusetts",
      "abbreviation": "MA"
  },
  {
      "name": "Michigan",
      "abbreviation": "MI"
  },
  {
      "name": "Minnesota",
      "abbreviation": "MN"
  },
  {
      "name": "Mississippi",
      "abbreviation": "MS"
  },
  {
      "name": "Missouri",
      "abbreviation": "MO"
  },
  {
      "name": "Montana",
      "abbreviation": "MT"
  },
  {
      "name": "Nebraska",
      "abbreviation": "NE"
  },
  {
      "name": "Nevada",
      "abbreviation": "NV"
  },
  {
      "name": "New Hampshire",
      "abbreviation": "NH"
  },
  {
      "name": "New Jersey",
      "abbreviation": "NJ"
  },
  {
      "name": "New Mexico",
      "abbreviation": "NM"
  },
  {
      "name": "New York",
      "abbreviation": "NY"
  },
  {
      "name": "North Carolina",
      "abbreviation": "NC"
  },
  {
      "name": "North Dakota",
      "abbreviation": "ND"
  },
  {
      "name": "Northern Mariana Islands",
      "abbreviation": "MP"
  },
  {
      "name": "Ohio",
      "abbreviation": "OH"
  },
  {
      "name": "Oklahoma",
      "abbreviation": "OK"
  },
  {
      "name": "Oregon",
      "abbreviation": "OR"
  },
  {
      "name": "Palau",
      "abbreviation": "PW"
  },
  {
      "name": "Pennsylvania",
      "abbreviation": "PA"
  },
  {
      "name": "Puerto Rico",
      "abbreviation": "PR"
  },
  {
      "name": "Rhode Island",
      "abbreviation": "RI"
  },
  {
      "name": "South Carolina",
      "abbreviation": "SC"
  },
  {
      "name": "South Dakota",
      "abbreviation": "SD"
  },
  {
      "name": "Tennessee",
      "abbreviation": "TN"
  },
  {
      "name": "Texas",
      "abbreviation": "TX"
  },
  {
      "name": "Utah",
      "abbreviation": "UT"
  },
  {
      "name": "Vermont",
      "abbreviation": "VT"
  },
  {
      "name": "Virgin Islands",
      "abbreviation": "VI"
  },
  {
      "name": "Virginia",
      "abbreviation": "VA"
  },
  {
      "name": "Washington",
      "abbreviation": "WA"
  },
  {
      "name": "West Virginia",
      "abbreviation": "WV"
  },
  {
      "name": "Wisconsin",
      "abbreviation": "WI"
  },
  {
      "name": "Wyoming",
      "abbreviation": "WY"
  }
]