import React, { useContext } from 'react';
import styled from 'styled-components';
import { useAlert } from '../managers/Alert';
import { ModalContext } from '../managers/ModalController';
import { useAuth } from '../managers/UserManager';
import { PlayableContext } from './context';


export const Chevron = styled.div`
    background-color: ${props => props.color};
    width: ${props => props.width}vw;
    height: calc(${props => props.width}vw * 1.7857);
    transform: rotateY(${props => props.flipped ? "180deg" : "0deg"});
    clip-path: polygon(70% 0, 100% 0, 30% 50%, 100% 100%, 70% 100%, 0% 50%);
    pointer-events: none;
`
const SmallChevron = styled.div`
    background-color: #00B3DB;
    width: ${props => props.width}vw;
    height: calc(${props => props.width}vw * 1.7857);
    transform: rotateY(${props => props.flipped ? "180deg" : "0deg"});
    margin-${props => props.flipped ? "left" : "right"}: -5.5vw;
    clip-path: polygon(70% -1%, 101% -1%, 30% 50%, 101% 101%, 70% 101%, -1% 50%);
    pointer-events: none;
`
// moved clip-path to 101 to avoid distortion at large widths


const MediumChevron = styled(SmallChevron)`
    background-color: #0085A1;
    margin-${props => props.flipped ? "left" : "right"}: -7vw;

`

const LargeChevron = styled(SmallChevron)`
    background-color: #006A81;
`

const Group = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transform: translateX(${props => props.flipped ? "-" : ""}3vw);
    pointer-events: none;
`

const Container = styled.div`
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    overflow-x: hidden;
    margin: -9vw 0 -6.2vw 0;
    z-index: -1;
`

const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`
const Button = styled.button`
    font-family: 'Roboto', sans-serif;
    border: none;
    background-color: #FFD41C;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.4vw;
    padding: 1vw 4vw;
    box-shadow: 0px 0.2vw 1vw rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    color: black;
`
const Italic = styled.span`
    font-family: 'Archivo Black', sans-serif;
    font-size: 2vw;
    font-weight: bold;
    color: white;

    font-style: italic;
`
const Subtitle = styled.span`
    margin-top: 0.5vw;
    white-space: wrap;
    color: white;
    font-size: 1.1vw;
    text-align: center;
    max-width: 20vw;
`

const Vertical = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 3vw;
`

export function Chevrons(){
    const { openModal } = useContext(ModalContext)
    const playable = useContext(PlayableContext)
    const {isLoggedIn} = useAuth()

    const alert = useAlert()

    const shouldOpen = () => {
        if(isLoggedIn){
            alert.success("You're already registered")
        } else {
            openModal()
        }
    }

    return(
        <Container >
            <Group flipped >
                <LargeChevron width={20} flipped />
                <MediumChevron width={13} flipped />
                <SmallChevron width={10}  flipped/>
            </Group>
            <Content>
                <Vertical>
                     <Italic>{playable ? "Registration is free!" : "Register to attend!"}</Italic>
                    {playable && <Subtitle>Sign up to receive event updates<br /> and exclusive giveaways</Subtitle>}
                    {!playable && <Subtitle>Click the button to register for both the pre-series event and Revelation Today: The Great Reset.<br /></Subtitle>}
                </Vertical>
                <Button onClick={shouldOpen}>{isLoggedIn ? "I'm Registered" : "Register Now!"}</Button>

            </Content>

            <Group>
                <SmallChevron width={10}  />
                <MediumChevron width={13}  />
                <LargeChevron width={20}  />
            </Group>
        </Container>
    )
}