import { Playable } from './components/context';
import { Layout } from './components/layout';
import Home from './pages/home';
import { SnackbarProvider } from 'notistack';
import { ModalProvider } from './managers/ModalController';
import { AlertProvider } from './managers/Alert';
import {Matomo} from './components/matomo';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Resources from './pages/resources';
import { UserProvider } from './managers/UserManager';
import { VideoProvider } from './components/video';
import { DecisionProvider } from './components/decision-card';
function App() {  



  return (
    <div>
      <Router>
      <Matomo />
      <Playable>
        <SnackbarProvider preventDuplicate>
        <AlertProvider>
          <UserProvider>
            <VideoProvider>
              
                  <ModalProvider>
                    <DecisionProvider>
                      <Layout >
                        <Switch>
                          <Route path="/resources">
                            <Resources />
                          </Route>
                          <Route path="/">
                            <Home  />
                          </Route>
                          <Redirect path="/response/:id" to={"/"} />
                        </Switch>
                      </Layout>
                    </DecisionProvider>
                </ModalProvider>
              
            </VideoProvider>
          </UserProvider>
          </AlertProvider>
        </SnackbarProvider>
      </Playable>
    </Router>
    </div>
  );
}

export default App;
