import React, { useEffect } from 'react';
import postscribe from 'postscribe'
import "./b.css"
import { useContext } from 'react';
import { ModalContext } from '../../managers/ModalController';
import styled from 'styled-components';
import { last } from 'lodash';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    // overflow-y: scroll;
    padding-bottom: 100px;
`

const Box = styled.div`

`


export const BlackBod = () => {
    const {closeModal: close} = useContext(ModalContext)
    useEffect(() => {
        postscribe('#bbox-root', '<script type="text/javascript"> window.bboxInit = function() {bbox.showForm("658c1cc5-12e3-4bbe-9f43-1e2bf5bea388"); }; (function () {  var e = document.createElement("script"); e.async = true; e.src = "https://bbox.blackbaudhosting.com/webforms/bbox-min.js";     document.getElementsByTagName("head")[0].appendChild(e);        } ());</script>')
        setTimeout(() => {
            getCloseButton()
        },4000)
    },[])

   const getCloseButton = () => {
        let button = document.createElement('button')
        button.id = "close-button"
        button.textContent = "Close"
        button.onclick = close

        let containers = document.getElementsByClassName("BBFormFieldContainer")
        let container = last(containers)
        console.log(container)
        container?.appendChild(button)

    }


    return(
    <Container>
        <div className="black-close" onClick={close}>
            &times;
        </div>
        <Box id="bbox-root" />
    </Container>)



}