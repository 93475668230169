import React from 'react';
import styled from 'styled-components'

import special from '../assets/special.jpg'
import mobile_special from '../assets/mobile_special.jpg'
import { OnlyDesktop, OnlyMobile } from '../pages/home';

const Image = styled.img`
    object-fit: contain;
    width: 90vw;
    heigth:  calc( 90vw / 2.3382);
    background-color: #001F2C;
    @media (max-width: 600px){
        width: 100vw;
    }
`

const Ref = styled.div`
    transform: translateY(-200px);
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
`

export const Special = React.forwardRef((props, ref) => {
    return(
        <Container >
            <Ref ref={ref}></Ref>
            <OnlyDesktop>
                <Image src={special}  alt="Special Pre-Series Event image" />
            </OnlyDesktop>
            <OnlyMobile>
                <Image src={mobile_special}  alt="Special Pre-Series Event image" />
            </OnlyMobile>
        </Container>
    )
})