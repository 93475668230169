import { random, endsWith } from "lodash"


export function addApostraphe(name) {
  if(!name){ return ''}
  if(endsWith(name, 's') || endsWith(name, 'S')){
    return name + "'"
  } else {

    return isUpperCase(name) ? name + "'S" : name + "'s"
  }
}
function isUpperCase(str) {
  return str === str.toUpperCase();
}
export function wait(time) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(),time)
    })
    
  }
  
  // debate on whether isNull should return true for "" as well as null and undefined
export function isNull(entity) {
  
    if(entity === null || entity === undefined || entity === ""){
      return true
    } else {
      return false
    }
  
  }

  export const formatPhone = (number) => {
    // by maerics
    let cleaned = ('' + number).replace(/\D/g, '')
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        let intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return number
  }
  // debate on whether isNull should return true for "" as well as null and undefined
export const delay = async (time) => setTimeout(() => true, time)
export function padZero(str, len) {
  len = len || 2;
  let zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}
export function invertColor(hex, bw = true, reduce = false) {

    let {r,g,b} = colorToRGB(hex)
    if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        if(reduce){
          return (r * 0.299 + g * 0.887 + b * 0.114) > 186
            ? '#444444'
            : '#DDDDDD';
        }
        return (r * 0.299 + g * 0.887 + b * 0.114) > 186
            ? '#464646'
            : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
}


export function randomColor() {
  const r = random(0, 255).toString(16);
  const g = random(0, 255).toString(16);
  const b = random(0, 255).toString(16);
  return "#" + padZero(r) + padZero(g) + padZero(b); 

}
export function colorToRGB(hex) {
  if(!hex){ return '#FFFFFF'}
  if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
      // throw new Error('Invalid HEX color.');
      // console.log('Invalid Hex color')
      return '#FFFFFF'
  }
  let r = parseInt(hex.slice(0, 2), 16),
  g = parseInt(hex.slice(2, 4), 16),
  b = parseInt(hex.slice(4, 6), 16);
  return {r,g,b}
}

export function lighten(hex, factor = 1) {
  let {r,g,b} = colorToRGB(hex)

  // lighten colors
      r = (r + (16 * factor))
      g = (g + (16 * factor))
      b = (b + (16 * factor))

  // control lightness to max 255
      r = Math.min(255, r).toString(16);
      g = Math.min(255, g).toString(16);
      b = Math.min(255, b).toString(16);

  return "#" + padZero(r) + padZero(g) + padZero(b);
}
export function darken(hex, factor = 1) {
  let {r,g,b} = colorToRGB(hex)

  // lighten colors
      r = (r - (16 * factor))
      g = (g - (16 * factor))
      b = (b - (16 * factor))

  // control lightness to max 255
      r = Math.max(0, r).toString(16);
      g = Math.max(0, g).toString(16);
      b = Math.max(0, b).toString(16);

  return "#" + padZero(r) + padZero(g) + padZero(b);
}
export function colorShift(hex, factor = 1) {
  let {r,g,b} = colorToRGB(hex)

  // lighten colors

        
        r = Math.max((r - 6 * factor), 0).toString(16);
        g = Math.min((g + 6 * factor), 255).toString(16);
        b = Math.max(b - 3 * factor, 0).toString(16);
    

  return "#" + padZero(r) + padZero(g) + padZero(b);
}



  
export function createManagerInfo(name, priority) { return {name, priority } }
export function colorNameToHex(colour)
{
    var colours = {"aliceblue":"#f0f8ff","antiquewhite":"#faebd7","aqua":"#00ffff","aquamarine":"#7fffd4","azure":"#f0ffff",
    "beige":"#f5f5dc","bisque":"#ffe4c4","black":"#000000","blanchedalmond":"#ffebcd","blue":"#0000ff","blueviolet":"#8a2be2","brown":"#a52a2a","burlywood":"#deb887",
    "cadetblue":"#5f9ea0","chartreuse":"#7fff00","chocolate":"#d2691e","coral":"#ff7f50","cornflowerblue":"#6495ed","cornsilk":"#fff8dc","crimson":"#dc143c","cyan":"#00ffff",
    "darkblue":"#00008b","darkcyan":"#008b8b","darkgoldenrod":"#b8860b","darkgray":"#a9a9a9","darkgreen":"#006400","darkkhaki":"#bdb76b","darkmagenta":"#8b008b","darkolivegreen":"#556b2f",
    "darkorange":"#ff8c00","darkorchid":"#9932cc","darkred":"#8b0000","darksalmon":"#e9967a","darkseagreen":"#8fbc8f","darkslateblue":"#483d8b","darkslategray":"#2f4f4f","darkturquoise":"#00ced1",
    "darkviolet":"#9400d3","deeppink":"#ff1493","deepskyblue":"#00bfff","dimgray":"#696969","dodgerblue":"#1e90ff",
    "firebrick":"#b22222","floralwhite":"#fffaf0","forestgreen":"#228b22","fuchsia":"#ff00ff",
    "gainsboro":"#dcdcdc","ghostwhite":"#f8f8ff","gold":"#ffd700","goldenrod":"#daa520","gray":"#808080","green":"#008000","greenyellow":"#adff2f",
    "honeydew":"#f0fff0","hotpink":"#ff69b4",
    "indianred ":"#cd5c5c","indigo":"#4b0082","ivory":"#fffff0","khaki":"#f0e68c",
    "lavender":"#e6e6fa","lavenderblush":"#fff0f5","lawngreen":"#7cfc00","lemonchiffon":"#fffacd","lightblue":"#add8e6","lightcoral":"#f08080","lightcyan":"#e0ffff","lightgoldenrodyellow":"#fafad2",
    "lightgrey":"#d3d3d3","lightgreen":"#90ee90","lightpink":"#ffb6c1","lightsalmon":"#ffa07a","lightseagreen":"#20b2aa","lightskyblue":"#87cefa","lightslategray":"#778899","lightsteelblue":"#b0c4de",
    "lightyellow":"#ffffe0","lime":"#00ff00","limegreen":"#32cd32","linen":"#faf0e6",
    "magenta":"#ff00ff","maroon":"#800000","mediumaquamarine":"#66cdaa","mediumblue":"#0000cd","mediumorchid":"#ba55d3","mediumpurple":"#9370d8","mediumseagreen":"#3cb371","mediumslateblue":"#7b68ee",
    "mediumspringgreen":"#00fa9a","mediumturquoise":"#48d1cc","mediumvioletred":"#c71585","midnightblue":"#191970","mintcream":"#f5fffa","mistyrose":"#ffe4e1","moccasin":"#ffe4b5",
    "navajowhite":"#ffdead","navy":"#000080",
    "oldlace":"#fdf5e6","olive":"#808000","olivedrab":"#6b8e23","orange":"#ffa500","orangered":"#ff4500","orchid":"#da70d6",
    "palegoldenrod":"#eee8aa","palegreen":"#98fb98","paleturquoise":"#afeeee","palevioletred":"#d87093","papayawhip":"#ffefd5","peachpuff":"#ffdab9","peru":"#cd853f","pink":"#ffc0cb","plum":"#dda0dd","powderblue":"#b0e0e6","purple":"#800080",
    "rebeccapurple":"#663399","red":"#ff0000","rosybrown":"#bc8f8f","royalblue":"#4169e1",
    "saddlebrown":"#8b4513","salmon":"#fa8072","sandybrown":"#f4a460","seagreen":"#2e8b57","seashell":"#fff5ee","sienna":"#a0522d","silver":"#c0c0c0","skyblue":"#87ceeb","slateblue":"#6a5acd","slategray":"#708090","snow":"#fffafa","springgreen":"#00ff7f","steelblue":"#4682b4",
    "tan":"#d2b48c","teal":"#008080","thistle":"#d8bfd8","tomato":"#ff6347","turquoise":"#40e0d0",
    "violet":"#ee82ee",
    "wheat":"#f5deb3","white":"#ffffff","whitesmoke":"#f5f5f5",
    "yellow":"#ffff00","yellowgreen":"#9acd32"};

    if (typeof colours[colour.toLowerCase()] != 'undefined')
        return colours[colour.toLowerCase()];

    return null;
}


