import React, { useContext } from 'react';
import { useState , useEffect} from 'react';
import { BehaviorSubject } from "rxjs"
import {Subject} from 'rxjs'
import {takeUntil} from 'rxjs/operators'


export class ModalManager{

    modalState = new BehaviorSubject(0)

    modalType = new BehaviorSubject("register") // register, donate, pray

    getModalType = () =>  this.modalType.asObservable()
    setDonateModal = () => this.modalType.next('donate')
    setRegisterModal = () => this.modalType.next('register')
    setPrayModal = () => this.modalType.next("pray")
    openModal = () =>  {
        this.modalState.next(1)
    }
    closeModal = () => {
        this.modalState.next(0)
        setTimeout(() => {
            this.modalType.next("register")
        }, 1000)   
    }
    expandModal = () => this.modalState.next(2)
    getModalState = () => this.modalState.asObservable()
    collapseModal = () => this.modalState.next(1)
    toggleModal = () => {
        let state = this.modalState.value
        switch(state){
            case 0: this.open(); break;
            case 1: this.expand(); break;
            case 2: this.collapse(); break;
            default: this.expand()
            
        }
        this.modalState.next(!this.modalState.value)
    }
}

const manager = new ModalManager()
export const ModalContext = React.createContext({...manager})
export const useModal = () => useContext(ModalContext)
export function ModalProvider({children}){

    const [modalState, setState] = useState(0)
    const [modalType, setType] = useState("")
    const [bibleQuestionState, setBibleQuestion] = useState(false)

    const openBibleQuestion = () => {
        setBibleQuestion(true)
    }


    const closeBibleQuestion = () => {
        setBibleQuestion(false)
    }
    const leaving = new Subject()
    useEffect(() => {
        manager.modalState
        .pipe(takeUntil(leaving))
        .subscribe(setState)

        manager.modalType
        .pipe(takeUntil(leaving))
        .subscribe(setType)

        return () => leaving.next()
    }, [leaving])

    return(
        <ModalContext.Provider value={{...manager, state: modalState, modalType, openBibleQuestion, closeBibleQuestion, bibleQuestionState}}>
            {children}
        </ModalContext.Provider>
    )
}
