import React from 'react';
import logo from '../assets/logo.png';
import styled from 'styled-components'
import { OnlyDesktop, OnlyMobile } from '../pages/home';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 110px;
    padding: 1vw;
`
const Text = styled.p`
    font-size: max(0.7vw, 1em);
    color: #626262;
    @media (max-width: 600px){
        font-size: 3vw
    }
`
const Logo = styled.img`
    object-fit: contain;
    width: 9em;
    @media (max-width: 600px){
        display: none;
    }

`

export function Footer(){
    return(
        <Container role="contentinfo">
            <Logo src={logo} alt="It Is Written Logo" />
            <OnlyDesktop><Text>Copyright &copy; 2021, It Is Written, Inc.  All rights reserved. It Is Written and Revelation Today are registered service marks and/or trademarks of It Is Written, Inc..</Text></OnlyDesktop>
            <OnlyMobile><Text>Copyright &copy; 2021, It Is Written, Inc.  All rights reserved.</Text></OnlyMobile>

        </Container>
    )
}