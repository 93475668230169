import React, {useState, useContext} from 'react';
import { Collapse, Drawer } from '@material-ui/core'
import styled from 'styled-components'
import { AuthModal, Input as _Input, Label} from './Modal/Modal';
import { useAuth } from '../managers/UserManager';
import { useAlert } from '../managers/Alert';
import {useModal} from '../managers/ModalController';

const Container = styled.div`
    margin: 1em auto;
    background: white;
    display: flex;
    flex-direction: column;
    min-height: 30vh;
    justify-content: center;
    align-items: flex-start;
`


const Input = styled(_Input)`

    min-width: 300px;
    max-width: 90vw;
    margin-left: 0;

    @media (max-width: 600px){
        max-width: 90vw;
        width: auto;
    }
`


const Textarea = styled.textarea`
    font-family: 'Roboto', sans-serif;
    min-width: 300px;
    max-width: 90vw;
    margin-left: 0;
    min-height: 10vh;
    min-width: min(320px, 90vw);
    border-raduis: 10px;
    @media (max-width: 600px){
        max-width: 90vw;
        width: auto;
    }
`

const Inner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: white;
    padding: 3vw;
    max-width: 90vw;
    @media (max-width: 600px){
        padding: 3vw 0;
    }

`

const RNButton = styled.button`
    font-family: 'Roboto', sans-serif;
    transition: all 0.2s ease-in-out;
    background-color: ${props => props.submitting ? '#ccc' : "#FFD41C"};
    padding: 15px 35px;
    font-weight: bold;
    color: black;
    border: none;
    font-size: 1em;
    border-radius: 4px;
    white-space: nowrap;
    @media (max-width: 1250px ){
        font-size: 0.7em;
    }
    `


const CloseButton = styled(RNButton)`
background-color: #ccc;
color: black;
`

const Horizontal = styled.div`
margin-top: 2em;
display: flex;
flex-direction: row;
justify-content: space-around;
align-items: center;
min-width: 300px;
`
export function AskJohnAQuestion(){
    const {user,  checkValidProfile, submitQuestion} = useAuth()
    const {bibleQuestionState: state,  closeBibleQuestion} = useModal()
    const [email, setEmail] = useState("")
    const [submitting, setSubmitting] = useState(false)
    const [tatext, setTAText] = useState("")
    const alerts = useAlert()
    const [needsAuth, setNeedsAuth] = useState(false)
    
    const close = () => {
        setNeedsAuth(false)
        closeBibleQuestion()
    }

    const check = async  (email) => {
        try{
            let response = await checkValidProfile(email, false)
            if(!response){
                setNeedsAuth(true)
            }
        } catch(e) {
            if(e.status === 404){
                setNeedsAuth(true)
            } else {
                alerts.default(e?.message)
            }
        }
    }   

    const renderEmailCheck = () => {

        const _check = () => {
            check(email)
        }

        const _setEmail = (e) => {
            setEmail(e.target.value)
        }

        const onAuth = () => {
            setNeedsAuth(false)
        }


        return(
            <Inner>
                <Collapse in={!user && !needsAuth}>
                <Label>Please Enter Your Email</Label>
                <Input name="email" placeholder="Email" onChange={_setEmail} />
                <Horizontal>
                    <RNButton onClick={_check}>Next</RNButton>
                    <CloseButton onClick={close}>Cancel</CloseButton>
                </Horizontal>
                </Collapse>
                <Collapse in={needsAuth}>
                    <AuthModal onSubmit={onAuth} embedded={true} originalEmail={email} onClose={close}/>
                </Collapse>
            </Inner>
        )
    }

    const onAuth = () => {

    }

    const submit = async  () => {
        if(submitting){
            return
        }
        try{
            setSubmitting(true)
            await submitQuestion(tatext)
            alerts.success("Question Submitted")
            setTAText("")
            close()
        } catch(e) {
            alerts.error(e)
        } finally {
            setSubmitting(false)
        }
        
    }

    const renderForm  = () => {

        const onTAChange = (e) => {
            setTAText(e.target.value)
        }

        return(
            <>
                <Textarea placeholder="Ask a question..." onChange={onTAChange} value={tatext}/>
                <Horizontal>
                    <RNButton onClick={submit} submitting={submitting}>Submit</RNButton>
                    <CloseButton onClick={close}>Cancel</CloseButton>
                </Horizontal>
            </>
        )
    }
    return(
        <Drawer open={state} anchor="bottom" onClose={close}>
            <Container>
                <h1>Ask Pastor John a Question</h1>
                {!!user && renderForm()}
                {!user && renderEmailCheck()}

            </Container>
        </Drawer>
    )
}