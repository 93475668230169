import { BehaviorSubject } from 'rxjs';
import { BASE as baseURL } from '../constants';
const Frisbee = require('frisbee');
const _ = require('lodash');
// import { Storage, Settings } from './exports';


export class NetworkBase{
    
    header = {
      tokenType: '',
      client: '',
      accessToken: '',
      uid: ''
    } 
    
   //production
    base = baseURL

    timeout = 10000 
    // settings = Settings
    api;
    _currentHeaders = new BehaviorSubject({})


    constructor(){
      this.api = new Frisbee({
        mode: "cors",
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
    }

    getHeaderSubscription = () => this._currentHeaders.asObservable()
    
    _get = async (path: string) => {  
      try{

          let response = await this.api.get(this.base + path)
          if(!response.ok){
              let error = await this.processError(response)
              return Promise.reject(error)
          }
          return response.body
      } catch(e) {
        // console.log(e)
          let error = {error: e.message || '', status: null}
          return Promise.reject(error)
      }
    }

    _getRaw = async (path: string) => {  
      // console.log('path =', path)
      let response
      try{
          response = await this.api.get(path)

          if(!response.ok){
              let error = await this.processError(response)
              return Promise.reject(error)
          }
          
          return response.body
      } catch(e) {
        console.error(e)
          let error = {error: e.message || '', status: null}
          return Promise.reject(error)
      }
    }
  
  
    processError = async (response: any) => {
      let { body , status}  = response
      
      if(status >= 500){
          
          return {error: "The Server had a problem with that request", status}
      } 
      if(!!body?.errors){
        let err = ''
          if(body?.errors?.full_messages){
            err = _.join(body?.errors?.full_messages, '; ')
          } else {
            err = JSON.stringify(body?.errors)
            .replace(/[[\]"{}]/g, "")
          }
          
          return { error: err, status}
      } else if (!!body?.error) {
        return { error: body?.error, status: status}
      } else {
        const keys = _.keys(body)

        const error = _.map(keys, (key: any) => String(key + ' ' + body[key])).join(';')
        return { error, status }
      }
      
      
      
  
  }
  
    extractError = (e) => {
      
      if(e?.error) { return e.error}
      if(e?.errors?.length === typeof 0) { return e.errors[0]}
      if(e?.errors?.full_messages?.[0]) {return e.errors.full_messages[0]}
      return e
    }
  
    _post = async (path: string, body: any) => {
        try{
          let response = await this.api.post(this.base + path, { body })
          if(!response.ok){
            
              let error = await  this.processError(response)
              return Promise.reject(error)
          }
          return response.body
      } catch(e){
        let error = {error: e.message || '', status: null}
        return Promise.reject(error)
      }
    }
  
    _put = async (path: string, body: any) => {
        try{
          let response = await this.api.put(this.base + path, { body })
          if(!response.ok){
            
              let error = await  this.processError(response)
              return Promise.reject(error)
          }
          return response.body
      } catch(e){
        let error = {error: e.message || '', status: null}
        return Promise.reject(error)
      }
    }
  
    getHeader = () => {
      let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token-type' : this.header.tokenType,
        'Client': "db7fe0da-da70-44e3-9ccf-c94298b2cafc",
        'access-token':  this.header.accessToken,
        'uid' : this.header.uid
      }
      return headers
    }
  
  
    _delete = async (path: string) => {

      try{
        let response = await fetch(this.base + path,{
          method: 'DELETE'
          })
        if(!response.ok){
          let error = await  this.processError(response)
          Promise.reject(error)
        }
        
        return true
      } catch(e){
        let error = {error: e.message || '', status: null}
        return Promise.reject(error)
        
      }
    }
  
  }
  

  