import AES from 'crypto-js/aes';
import Crypto from 'crypto-js';
import _ from 'lodash';

export class StorageManagement{
    init = async () => {
        // console.log('storage lives')
        return true
    }
    ekey = 'asdfjkl';
    decrypt = (item) => {
        if(!item){ return }
        try{
            const bytes = AES.decrypt(item, this.ekey)
            const raw = bytes.toString(Crypto.enc.Utf8)
            const revived = JSON.parse(raw)
            return revived
        } catch(e) {
            // console.log(e)
        }
        
    }
     
    encrypt = (item) => {
        let str = JSON.stringify(item)
        const encrypted = AES.encrypt(str, this.ekey).toString()
        return encrypted
    }

    getEncryptedItems = (table_name) => {
        return new Promise((resolve, reject) => {
            this.get(table_name)
                .then(encrypted_results => { 
                    let results = _.map(encrypted_results, result => this.decrypt(result))
                    resolve(results)
                })
                .catch(e => {
                    reject(e)
                })
            })
        } 

    getEncryptedTable = (table_name) => {
        return new Promise((resolve, reject) => {
            this.get(table_name)
            .then(encrypted_table =>{ 
                let results = this.decrypt(encrypted_table)
                resolve(results)
            })
            .catch(e => {
                reject(e)
            })
        })
    } 
   
    setEncryptedItems = async (items, table_name) => {
        // console.log('setting Items', table_name)
        try{
            let local_items = await Storage.get(table_name) 
            if(!local_items){ local_items = {} }
    
            _.forEach(items, item => {
            let id = item.id
            local_items[id] = Storage.encrypt(item)
            })
            
            let success = await Storage.set(local_items, table_name)
            return success   
        } catch(e) {
            // console.log(e)
        }
    }

    setEncryptedTable = async (items, table_name) => {
        // console.log('set Table', table_name)
        let encrypted_items = this.encrypt(items)
        return this.set(encrypted_items, table_name)
    }
   
    get = async (table_name) => {
            let result = await localStorage.getItem(table_name)
            return JSON.parse(result)
    }
    
   set = (items, table_name) => { 
    //    console.log('setting Storage')
        return new Promise((resolve, reject) => {     
        let value = JSON.stringify(items)     
         localStorage.setItem(table_name, value, (e) => {
            if(e) { reject(e)
            } else { resolve() }
        })
    })
   }



     clearTable = async (table) => {
         try{
             table && localStorage.removeItem(table)
             return true
         } catch(e){
             return Promise.reject(e)
         }
     }

 }

 