import { flatMap, reverse } from 'lodash';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { useAlert } from '../managers/Alert';
import { ModalContext } from '../managers/ModalController';
import { useAuth } from '../managers/UserManager';
import { OnlyDesktop, OnlyMobile } from '../pages/home';
import week_data from '../week_data';
import { PlayableContext } from './context';
import dayjs from 'dayjs';
import { useVideo } from './video';

const Container = styled.div`
    width: 100vw;
    height: 15vw;
`
const Color = styled.div`
    transform: translateY(-99%);
    height: 10.5vw;
    width: 100vw;
    background-color: rgba(30, 50, 100 , 0.5);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
`

const Left = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Right = styled(Left)`
    background-color: white;
    width: 50vw;
    height: 24.5vw;
    margin-left: 1vw;
    padding: 1.5vw;
    transform: translateY(-5vw);
    box-shadow: 0px 1vw 2vw 2vw rgba(0, 0, 0, 0.1);
`
const Title = styled.span`
    font-size: 3vw;
    font-weight: bold;
    color: white;
    margin-left: 6vw;
    font-family: Archivo;
`

const Subtitle = styled(Title)`
    font-size: 2.2vw;
`

const UpperText = styled.p`
    font-weight: bold;
    font-size: 2.8vw;
    margin: 0.2vw 0.4vw;
    font-family: Roboto;

`
const LowerText = styled(UpperText)`
    font-weight: 400;
    font-size: 1.6vw;
    margin: 0 0.5vw;
    margin-top: 1vw;
    font-family: Roboto;
`
const Button = styled.button`
    font-family: 'Roboto', sans-serif;
    position: absolute;
    bottom: 0;
    left: 0;
    border: none;
    color: black;
    background-color: #FFD41C;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2vw;
    padding: 2vw 6vw;
    transform: translate(9vw, 8.5vw);
    box-shadow: 0px 0.2vw 1vw rgba(0, 0, 0, 0.6);
    border-radius: 4px;
`

const Vertical = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    transform: translateY(-50vw);
    margin-bottom: -50vw;
`
const MobileButton = styled(Button)`
    position: relative;
    transform: translate(0, 0);
    margin: 0.2em auto;
    height: 70px;
    font-size: 2em;
`
const MobileSubtitle = styled.span`
    font-family: 'Roboto', sans-serif;
    color: white;
    font-size: 1.3em;
    font-weight: bold;
    font-style: italic;
    margin: 0.4em auto;
`

const MobileTextContainer = styled(Right)`
    height: auto;
    width: 90vw;
    position: relative;
    transform: translate(0, 0);
    margin-top: 0.5em;
    margin-left: 0;
    box-shadow: 0px 1em 1em 1em rgba(0, 0, 0, 0.1);
`

const MobileUpper = styled(UpperText)`
    font-size: 1.5em;
    padding: 0.5em;
`

const MobileLower = styled(LowerText)`
    font-size: 1.2em;
    padding: 0 0.7em 0.7em;
`
const Link = styled.div`
    transform: translateY(-50vw);
    @media (max-width: 600px){
        transform: translateY(-70vw);
    }
`
 export const ParallaxBottom = React.forwardRef(({title, subtitle, gotoWatch}, ref) => {
    const { openModal } = useContext(ModalContext)
    const playable = useContext(PlayableContext)
    const alert = useAlert()
    const {setVideo} = useVideo()
    const {markAttended} = useAuth()

    const { isLoggedIn } = useAuth()

    const getLatest = () => {

        let nights = flatMap(week_data)
        const now = dayjs()
        //find first video wherenow is after start date and now is  before end date
        for (let night of nights){
            if(now.isAfter(night.datetimert) && now.isBefore(night.datetimert_end)){
                return night
            }
        }

        // no active video.
        // fing first active night from last to first where
        // start time is before now.
        for ( let night of reverse(nights)){
            if(now.isAfter(night.datetimert)){
                return night
            }
        }

        // we've hit an error if we've come this far.
        return null
    }

    const shouldOpen = async () => {
        if(isLoggedIn){
            if(playable){
                try{
                    let night = getLatest()
                    let response = await fetch(night.video_link)
                    let video = await response.json()

                    markAttended(night?.id)
                    setVideo(video)
                    gotoWatch()
                } catch(e) {
                    alert.error("We had a problem playing that video.")
                }
       


            } else {
                alert.success("You're already registered")
            }
            

        } else {
            openModal()
        }
    }
    
    return(
        <>
        <Container >
            <Link  ref={ref}></Link>
            <OnlyDesktop>
            <Color>
                <Left>
                    <Title >{title}</Title>
                    {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
                    <Button onClick={shouldOpen} >{isLoggedIn ?  !!playable ? "Click to Watch" : "I'm Registered" : "Register Now"}</Button>
                </Left>
                <Right>
                    <UpperText>
                        Ancient predictions speak to today's world and to the future! The United States of America: today, the greatest nation on the planet—tomorrow?
                    </UpperText>
                    <LowerText>
                        This continuing series will provide answers to life's most pressing questions.
                        The Bible outlines coming events with
                        remarkable clarity.  Don't miss a night of Revelation Today.
                    </LowerText>

                </Right>
            </Color>
            </OnlyDesktop>
  
        </Container>
        <OnlyMobile>
            <Vertical>
                <MobileButton onClick={shouldOpen}>{isLoggedIn ? "Click to Watch" : "Register Now"}</MobileButton>
                <MobileSubtitle>Registration is Free!</MobileSubtitle>
                <MobileTextContainer>
                    <MobileUpper>
                        Ancient predictions speak to today's world and to the future! The United States of America: today, the greatest nation on the planet—tomorrow?
                    </MobileUpper>
                    <MobileLower>
                        This continuing series will provide answers to life's most pressing questions.
                        The Bible outlines coming events with
                        remarkable clarity.  Don't miss a night of Revelation Today.
                    </MobileLower>

                </MobileTextContainer>
            </Vertical>
        </OnlyMobile>
        </>
    )
})
