import React from 'react';
import styled from 'styled-components';
import map from '../assets/map.jpg';
import pin from '../assets/pin.png';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    margin: 10vw auto;
    @media (max-width: 600px){
        flex-direction: column;
        margin: 6vw auto;
    }
`
const Left = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 600px){
        margin-bottom: 6vw;
    }
`
const Image = styled.img`
    object-fit: contain;
    width: 55vw;
    height: calc( 55vw / 1.79);
    @media (max-width: 600px){
        width: 100%;
        height: calc( 100% / 1.79);
    }
`
const Pin = styled.img`
    object-fit: contain;
    width: 5vw;
    height: calc( 5vw / 0.7238);
    margin-right: 2vw;
    @media (max-width: 600px){
        width: 12vw;
        height: calc( 12vw * 1.79);
        marign-right: 0;
        transform: translateY(-1.5vw);

    }
`
const Address = styled(Left)`

`


const Location = styled.span`
    font-size: 2.5vw;
    color: #FFD41C;
    font-weight: 400;
    margin-bottom: 1vw;
    text-transform: uppercase;
    letter-spacing: 0.2vw;
    margin-bottom: 3vw;
    @media (max-width: 600px){
        font-size: 5vw;
        margin-bottom: 4vw;
    }
`
const Title = styled.span`
    font-size: 1.8vw;
    color: white;
    font-weight: 600;
    margin-bottom: 1vw;
    @media (max-width: 600px){
        font-size: 5vw;
    }
`

const Text = styled.span`
    color: white;
    font-size: 1.8vw;
    @media (max-width: 600px){
        font-size: 5vw;
    }
`

export const Map =  React.forwardRef((props, ref) => {
    return(
        <Container>
            <Left>
            <Location  ref={ref}>Location</Location>
            <Row>
                <Pin src={pin} alt="pin" />
                <Address>
                    <Title>Church Auditorium</Title>
                    <Text>7450 Standifer Gap Rd</Text>
                    <Text>Chattanooga, TN 37421</Text>
                </Address>
            </Row>
            </Left>
            <Image  src={map} alt={"Image of a map"} />
        </Container>
    )
})