import Ract, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { Navbar } from '../components/navbar'
import { Page, OnlyDesktop, OnlyMobile } from './home'
import full from '../assets/full.jpg';
import main from '../assets/main.jpg';
import mobile_background from '../assets/mobile_background.jpg'
import title_crop from '../assets/title_crop.png'
import week_data, { guides } from '../week_data';

import { map } from 'lodash';
import { useAuth } from '../managers/UserManager';

import dayjs from 'dayjs';
import { useModal } from '../managers/ModalController';
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
dayjs.extend(isSameOrAfter)

const red = "#D7342C"
const grey = "#C4C4C3"
const grey_number = "#676768"


const P = styled.div`
    padding-top: 100px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding-bottom: 10vw;
    min-height: 100vh;
    z-index: 0;
    background-color: #02080A;
    @media (max-width: 600px){
        padding-top: 10px;
        background-color: #02080A;
    }
`
const Center = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flext-start;
    align-items: flex-start;
    background-color: #01010366;
    align-self: stretch;
    margin: 100px auto;

    width: 80vw;
    padding:  5vw;
    z-index: 0;

    @media (max-width: 600px){
        margin-top: 40vw;
    }
`
const yellow = "#F9CF19"
const color = "#FFD41C"

const Image = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100vw * 1.5);
    filter:  hue-rotate(50%) grayscale(20%);
    object-fit: cover;
`

const Background = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100vw * 1.5);
    background: linear-gradient(#003134dd, #02080Add );
    filter: saturate(200%) brightness(200%);
`
const Head = styled.img`
    width: 50vw;
`
const Hori = styled.div`
    display: flex;
    justify-content: space-between;
`
const Topbar = styled.div`
    padding-top: 2vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 2vw;
`

const Title = styled.h1`
    padding-top: 1vw;
    color: ${yellow};
    text-transform: uppercase;
    font-size: 1.8vw;
    @media (max-width: 600px){
        font-size: 3.8vw;
    }
`

const Subtitle = styled.span`
    font-size: 1vw;
    color: #fafafa;
    @media (max-width: 600px){
        font-size: 1.5vw;
    }
`
const Week_ = styled.div`
    transition: all 0.3s ease-in-out;
    font-size: 1.8vw;
    margin: 0 1vw 0 0vw;
    padding: 0.6vw 1vw;
    color: ${props => props.selected ? "white" : "#A5A5A5"};
    text-transform: uppercase;
    cursor: pointer;
    z-index: 3;
    border: ${props => props.selected ? `1px solid ${color}` : '1px solid transparent'};
    @media (max-width: 600px){
        font-size: 3.3vw;
    }
`
const Week = (props) => (<Week_ {...props}>Week {props.week}</Week_>)


const _Number = styled.div`
    width: min(6vw, 80px);
    height: min(6vw, 80px);
    color: ${red};
    background-color: ${yellow};
    font-size: min(6vw, 4em);
    padding: min(1vw , 40px);
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Archivo', sans-serif;

    @media (min-width: 601px) and (max-width: 800px){
        padding: 4vw;
        font-size: 10vw;
    }

    @media (max-width: 600px){
        padding: 5vw;
        font-size: 10vw;
    }
`

const LeftColumn = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 0.5vw;
    align-items: flex-start;
`

const CellHori = styled(Hori)`

`

const LeftRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

export const RNButton = styled.button`
    font-family: 'Roboto', sans-serif;
    transition: all 0.2s ease-in-out;
    background-color: ${props => props.bbl ? "#BBE3E1" : "#FFD41C"};
    padding: min(2vw, 10px) min(5vw, 25px);
    font-weight: bold;
    border: none;
    font-size: 1vw;
    // margin-right: min(2em, 2vw);

    border-radius: 4px;
    white-space: nowrap;
    color: black;
    @media (max-width: 1250px ){
        font-size: 3vw;
        // margin-right: min(1em, 1vw);
    }
`

export const Line = styled.div`
    width: 100%;
    padding: 1vw 0vw;
    @media (max-width: 600px){
        margin-top: 4vw;
    }
    border-top: ${({index}) => index ? "1px solid grey" : "none"};
`


export const Link = styled.a`
    font-size: 1.5vw;
    margin: 1vw 0 0 0 ;
    white-space: nowrap;
    font-style: italic; 
    color: #ccc;
    padding-left: 2vw;
    cursor: pointer;
    @media (max-width: 600px){
        font-size: 3.5vw;
    }
`
const Col = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
` 
const Episode = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
    padding: 1vw 0;
    background-color: #676868;
    font-size: 1.5vw;
`

export const BibleStudyLink = ({lessons, id}) => {
    let auth = useAuth()

    const [user, setUser] = useState()
    useEffect(() => {
        const call = async () => {
            let _user = await auth.getUserSnapshot()
            setUser(_user)
        }
        call()
    }, [auth.isLoggedIn])

    const mark = () => {
        auth.markAttended(id)
    }


    if(!user?.id){
        return <></>
    }

    return(
        <LeftRow>{
        map(lessons, (lesson, index) => (

                <Link 
                    onClick={mark}
                    href={`https://itiswritten.study/rte/lesson/${lesson}/${user?.email}/${user?.id}`} 
                    // href={`http://localhost:4200/rte/lesson/${lesson}/${user?.email}/${user?.id}`} 
                    target="_blank"><RNButton bbl>Study {lesson}</RNButton>
                    </Link>
                )
            
        )}
        </LeftRow>
    )
}

export const ResourceLink = ({downloads, id, group = false, groupTitle = "", groupLink=null}) => {
    let auth = useAuth()

    const [user, setUser] = useState()
    useEffect(() => {
        const call = async () => {
            let _user = await auth.getUserSnapshot()
            setUser(_user)
        }
        call()
    }, [auth.isLoggedIn])

    const mark = () => {
        auth.markAttended(id)
    }


    if(!user?.id){
        return <></>
    }

    return(
        <LeftRow>{
        map(downloads, download => (
            <Link 
                style={{marginTop: 0}}
                onClick={mark}
                href={group && groupLink ? groupLink : download?.link} 
                download>
                    <RNButton >{groupTitle || download?.title + " PDF"} </RNButton>
            </Link>
            )
        )}
        </LeftRow>
    )
}

const Number = ({index}) => {

    return (
    <Col>
        {(index - 1 === 0) && <Episode>Episode</Episode>}
        <_Number >{index}</_Number>
    </Col>
    )
}

const Cell = ({index, lessons, downloads, id}) => {
    let auth = useAuth()

    const [user, setUser] = useState()
    useEffect(() => {
        const call = async () => {
            let _user = await auth.getUserSnapshot()
            setUser(_user)
        }

        call()


    }, [auth.isLoggedIn])

    const mark = () => {
        auth.markAttended(id)
    }


    return(

        <CellHori >
            <Number index={index}/>
            <LeftColumn>

                <ResourceLink 
                    style={{marginTop: 0}}
                    downloads={downloads}
                    id={id}
                    />

                <BibleStudyLink lessons={lessons} id={id}/>
                
            </LeftColumn>
        </CellHori>
    )
}

export default function Resources(){

    const [selectedWeek, setWeek] = useState(3)

    const {user} = useAuth()
    const {openModal}  = useModal()

    return(
        <>
            <Navbar />
            <P>
                <OnlyDesktop>
                    <Image src={main} alt="background"/>
                    <Background />
                </OnlyDesktop >
                <OnlyMobile>
                    <Image src={mobile_background} alt="background"/>
                    <Background />
                </OnlyMobile >
                {!user && <Center>
                    <Head src={title_crop}></Head>
                    <Title>
                        Login or register to access the free resources.
                    </Title>
                    <RNButton onClick={openModal}>
                      Register/Login
                    </RNButton>

                    </Center>}
                {!!user &&<Center>
                    <Head src={title_crop}></Head>
                    <Hori>
                        <Title>
                            Study Guides & Free Downloads
                        </Title>

                    </Hori>
                    <Subtitle>
                        Click the buttons below to access the free resources that go along with each episode.
                    </Subtitle>
                    <Topbar>
                        { week_data.map((w, i) => {
                            const onClick = () => setWeek(i)
                            return <Week 
                                key={"week-" + i}
                                onClick={onClick}
                                week={i + 1}
                                selected={selectedWeek === i } 
                            />
                        })}
                        </Topbar>
         
                    <LeftColumn>
                        {map(week_data[selectedWeek], (night, index) => {
                           if(!dayjs().isSameOrAfter(night?.datetimert)){
                            return <></>
                           }
                           
                           return(
                            <Line index={index}>
                                <Cell  
                                        index={night?.number}
                                        lessons={night?.lessons}
                                        downloads={night?.downloads}
                                        id={night?.id}
                                />
                            </Line>
                        )})}
                        </LeftColumn>
                </Center>}
                
            </P>
        </>
    )
}