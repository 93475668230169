import { Collapse } from '@material-ui/core';
import _ from 'lodash';
import React, { createContext, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAlert } from '../managers/Alert';
import { PlayableContext } from './context';
import {VideoPlayer} from './video-player'
const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    margin: 4vw 0;
    @media (max-width: 600px){
        margin: 8vw 0 4vw 0;
    }
    z-index: 1000;
`

const Margin = styled.div`
    width: 75vw;
    aspect-ratio: 16/9;
    height: calc(75vw * 9 / 16);
    obejct-fit: contain;

    @media (max-width: 600px){
        width: 93.5vw;
        height: calc(93.5vw * 9 / 16);
    }
`


const VideoContext = createContext()


export const VideoProvider = ({children}) => {
    const [video, setVideo] = useState()
    const [state, setVideoState] = useState()
    const playable = useContext(PlayableContext)
    const id = playable ? 13037 : 13087
    const alerts = useAlert()


    useEffect(() => {
        const call = async () => {
            let res = await fetch(`https://us-central1-iiwtv-139f6.cloudfunctions.net/v1/videos/${id}`)
            let data = await res.json()
            setVideo(data)
        }
        call()
        
    }, [])

    const setPlayState = ({type}) => {
        setVideoState(type)
        console.log(type)
    }

    const onAutoSet = (video) => {
        if(state === "playing"){
            alerts.default(
                "The next presentation is available. Do you want to stop the current video and play it?",
                [
                    {text: "Yes", onPress: () => setVideo(video)},
                    {text: "No", onPress: () => {}}
                ]
            )
            alert()
        } else {
            setVideo(video)
        }
    }


    return(
        <VideoContext.Provider value={{video, setVideo, setPlayState, state, onAutoSet}}>
            {children}
        </VideoContext.Provider>
    )
}

export const useVideo = () => useContext(VideoContext)

export function Video({gotoWatch}){

    const {video: _video, setVideo} = useVideo()
    const [video, set] = useState(_video)
    const playable = useContext(PlayableContext)
    const id = playable ? 13037 : 13087
    const [autoplay, setautoplay] = useState(null)

    useEffect(() => {
        if(!video){
            set(_video)
            if(!!_video && _video?.id !== id){
                setautoplay('play')
                gotoWatch()
            }
        }else if (video?.id === _video.id) {
            return
        } else if (_video?.id !== !video?.id ){
            set(null)
        }
    },[_video, video])


    return(

        <Container>
            <DesktopCollapse in={!!video} timeout={1000} >
                <Margin>
                {!!video &&
                    <VideoPlayer video={video} autoplay={autoplay}/>
                }
                </Margin>
            </DesktopCollapse>
        </Container>
    )
}

const DesktopCollapse = ({children, video, timeout}) => {

    if(window.innerWidth > 600){
        return(
            <>
            {/* <Collapse in={!!video} timeout={timeout} > */}
                {children}
            {/* </Collapse> */}
            </>
        )
    } else {
        return children
    }
}