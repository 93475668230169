import * as React from 'react';
import styled from 'styled-components';

import mpwj from '../assets/mpwj.png'
import book from '../assets/book.png'
import gift from '../assets/gift.png'


const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 3vw 0;
    padding: 0 15vw;

    @media (max-width: 600px){
        justify-content: space-between;
        padding: 0 5vw;
    }
`

const Vert = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`
const Title = styled.span`
    font-size: 1.9vw;
    text-transform: uppercase;
    letter-spacing: 0.3vw;
    color: white;
    @media (max-width: 600px){
        font-size: 4.9vw;
    }
`

const Subtitle = styled.span`
    font-size: 1.3vw;
    color: white;
    @media (max-width: 600px){
        font-size: 3.2vw;
    }
`

const Image = styled.img`
    width: 7vw;
    height: 7vw;
    object-fit: contain;
    margin: 0.5vw;
    @media (max-width: 600px){
        width: 14vw;
        height: 14vw;
        margin: 3.5vw;
    }
`

const Gift = styled(Image)`
@media (min-width: 601px){
    width: 6vw;
    height: 6vw;
    margin-bottom: 1.5vw;
}
`
const MPWJ = styled(Image)`
    @media (min-width: 601px){
        width: 15vw;
        height: 7vw;
    }
    @media (max-width: 600px){
        width: 25vw;
    }

`

const Book = styled(Image)`
    @media (min-width: 601px){
        width: 6vw;
        height: 6vw;
        margin-bottom: 1.5vw;
    }
`

export function Gifts(){
    return(
        <Container>
            <Vert>
                <Gift src={gift} alt="gift" />
                <Title>Free Gift</Title>
                <Subtitle>on opening night</Subtitle>

            </Vert>
            <Vert>
                <MPWJ src={mpwj} alt="kids"/>
                <Title>Children's</Title>
                <Subtitle>program</Subtitle>

            </Vert>
            <Vert>
                <Book src={book} alt="book" />
                <Title>Free</Title>
                <Subtitle>study resources</Subtitle>

            </Vert>
        </Container>
    )
}