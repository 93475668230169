import React, {useContext} from 'react';
import {isString} from 'lodash';
import { AlertTypes, AlertPositions } from '../constants';
import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';

export const AlertContext = React.createContext()


export function useAlert() {
  return useContext(AlertContext)
}

export const AlertProvider = ({children}) => {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const setDefault = (message, actionConfig, anchorOrigin = AlertPositions.topLeft) => {
      if(!isString(message)){
        message = JSON.stringify(message)
      }
     updateAlerts({message, actionConfig, variant: AlertTypes.default, anchorOrigin})
    }
    const setInfo = (message, actionConfig, anchorOrigin = AlertPositions.topLeft) => {
      if(!isString(message)){
        message = JSON.stringify(message)
      }
     updateAlerts({message, actionConfig, variant: AlertTypes.info, anchorOrigin})
    }
    const setWarning = (message, actionConfig, anchorOrigin = AlertPositions.topLeft) => {
      if(!isString(message)){
        message = JSON.stringify(message)
      }
     updateAlerts({message, actionConfig, variant: AlertTypes.warning, anchorOrigin})
    }
    const setError = (message, actionConfig, anchorOrigin = AlertPositions.topLeft) => {
      if(!isString(message)){
        message = JSON.stringify(message)
      }
     updateAlerts({message, actionConfig, variant: AlertTypes.error, anchorOrigin})
    }
    const setSuccess = (message, actionConfig, anchorOrigin = AlertPositions.topLeft) => {
      if(!isString(message)){
        message = JSON.stringify(message)
      }
     updateAlerts({message, actionConfig, variant: AlertTypes.success, anchorOrigin})
    }
    const setAlert = (title, message, actionConfig) => this.warning(message, actionConfig)

    const updateAlerts = (newAlert) => {
        const {message, variant, anchorOrigin, actionConfig} = newAlert
        let action 
        if(actionConfig){
            let first = (key) => {
                closeSnackbar(key)
                // alert(key)
                actionConfig[0].onPress()
            }
            let second = (key) => {
                closeSnackbar(key)
                actionConfig[1].onPress()
            }
            action = key => (
                <>
                    <Button onClick={() => first(key)} style={{color: 'white'}}>
                        {actionConfig[0].text}
                    </Button>
                    <Button onClick={() => second(key)} style={{color: 'white'}}>
                        {actionConfig[1].text}
                    </Button>
                </>
            );
        }
   
        enqueueSnackbar(
            message, 
            {
                variant,
                anchorOrigin,
                action
            }) 
        } 
    


  return (
    <AlertContext.Provider value={{
        default: setDefault,
        info: setInfo,
        warning: setWarning,
        error: setError,
        success: setSuccess,
        alert: setAlert
    }}>
      {children}
    </AlertContext.Provider>

  )
}



