import React, {  useEffect, useState } from 'react';
import { Checkbox, Collapse, Fade } from '@material-ui/core';

import { map } from 'lodash';
import { Database } from '../../managers/exports';
// import { formatPhone } from '../../utilities';
import "./Modal.css";
import {COUNTRIES, STATES} from '../../constants';
import { reduce } from 'lodash'
import { BlackBod } from '../BlackBod/BlackBod';
import styled from 'styled-components';
import { useContext } from 'react';
import { useAlert } from '../../managers/Alert'
import { ModalContext } from '../../managers/ModalController';
import { PlayableContext } from '../context';
import { useAuth } from '../../managers/UserManager';

const A = styled.a`
    text-decoration: underline;
    font-size: 1em;
    font-weight: normal;
    color: blue !important;
    margin: 0;
`

const FormGroup = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`
const Container = styled.div`
    position: fixed;
    top: 0;
    left:0;
    right: 0;
    bottom: 0;
    z-index:18;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: scroll;
    align-items: center;
    background-color: #00000077;
    transition: all 0.3s ease-in-out;
        @media  (max-width: 600px) {
            // padding: 0 !important; 
            padding-top: 10vw;
        }
`

export const Input = styled.input`
    height: 1em;
    padding: 1em;
    width: 90%;
    ${props => props.hasError ? "border: 1px solid red" : "border: 1px solid black"};
    @media (max-width: 600px){
        width: auto;
        max-width: 85vw;
    }
`
export const Label = styled.label`
    margin-bottom: 0.5em;
    font-size: 0.8em;
`

const ModalContainer = styled.div`
    z-index: 200;
    overflow-y: scroll;
    width: 500px;
    max-width: 90vw;
    margin: 0 auto;
    background-color: white;
    border-radius: 0.3rem;
    border: 1px solid rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    @media (min-width: 576px){
        max-width: 500px;
        max-width: 90vw;
    }

`

const Form = styled.form`
    height: auto;
    position: relative;
    @media (min-width: 576px) {
        padding: 3rem;
        max-width: 80%;
    }
`
export const LeftColumn = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex;
    margin: 0.8vw auto;
    margin-bottom: 0.5em;
`


const ModalHeader = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    padding: 1rem 1rem;
`

const Select = styled.select`
    width: 97%;
    padding: 0 1em;
    color: black;
    height: 40px;
    border-radius: 4px;
    -webkit-appearance: none;
    @media (max-width: 600px){
        margin: 0 auto;
        // width: 90%;
    }
`

const Submit = styled.input`
    font-family: 'Roboto', sans-serif;
    transition: all 0.2s ease-in-out;
    background-color: #FFD41C;
    padding: 10px 25px;
    font-weight: bold;
    border: none;
    color: black;
    font-size: 1em;
    margin-right: min(2em, 2vw);
    border-radius: 4px;
    white-space: nowrap;
    -webkit-appearance: none;
    @media (max-width: 1250px ){
        font-size: 0.7em;
        margin-right: min(1em, 1vw);
    }
`

const Close = styled.button`
    font-family: 'Roboto', sans-serif;
    transition: all 0.2s ease-in-out;
    background-color: #ccc;
    color: black;
    padding: 10px 25px;
    font-weight: bold;
    border: none;
    font-size: 1em;
    margin-right: min(2em, 2vw);
    border-radius: 4px;
    white-space: nowrap;
    -webkit-appearance: none;
    @media (max-width: 1250px ){
        font-size: 0.7em;
        margin-right: min(1em, 1vw);
    }
`

export const Modal = props => {
    const {state, modalType: type} = useContext(ModalContext)

    return(
        <Fade in={!!state} timeout={500}>
            <Container>
                {type === "donate" && <BlackBod />}
                {type === "register" && <AuthModal />}
            </Container>
        </Fade>
    )
}


export const AuthModal = ({onSubmit, embedded, originalEmail, onClose: _onClose}) => {
    const [consent, setConsent] = useState(false)
    const playable = useContext(PlayableContext)
    const UserManager = useAuth()

    const [errors, setErrors] = useState({
        email: false,
        first: false,
        last: false,
        phone: false,
        zip: false,
        country: false,
        consent: false
    })




    const alert = useAlert()
    const {closeModal, expandModal, state } = useContext(ModalContext)

    const onClose = () => {
        if(!!_onClose){
            _onClose()
        } else {
            closeModal()
        }
        
    }

    const onConsentChange = () => {
        setConsent(!consent)
    }


    // const formatPhoneNumber = (value)=> {
    //     const formatted = formatPhone(value)
    //     // setPhone(formatted)
    // }

    const onCheck = async (email) => {

        try{
            if(email === "" || !email){
                alert.default("Email Required")
                return
            }
           let registered =  await UserManager.checkValidProfile(email, !playable)
           
           if(registered){
               alert.success("You're all set!")
               onClose()
               return
           } else {
            expandModal()
           }

        } catch(e) {
            alert.warning(e)

        }
    }
    const hasFormError = async (target) => {
        
        const email =  target?.email?.value
        const first =  target?.first_name?.value
        const last =  target?.last_name?.value
        const phone =  target?.phone?.value
        const zip =  target?.zip?.value
        const country =  target?.country?.value


    
        let _errors = {...errors}
        const emailreg = RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i)
        _errors.email   = !email || (!!email && email.trim() === "") || !emailreg.test(email)
        _errors.first   = !first || (!!first && first.trim() === "")
        _errors.last    = !last || (!!last && last.trim() === "")

        if(country === "US"){
            const zipreg = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/i)
            _errors.zip     = !zip || !zipreg.test(zip)
        } else {
            _errors.zip     = !zip 
        }
        
        _errors.country = !country || (!!country && country.trim() === "")
        if(country === "CA"){
            const phoneReg = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
            _errors.phone = !phoneReg.test(phone)
        } else if(country === "US" ){
            try{
                let response = await Database._getRaw(`https://us-central1-iiwtv-139f6.cloudfunctions.net/v1/valid-phone/${phone}`)
                if(!response?.carrier?.type){
                     alert.error(`${phone} doesn't seem to be a valid phone number.  Please enter a valid number.`)
                     _errors.phone = true
                } else {
                    _errors.phone = false
                }
            } catch(e) {
                if(e.status > 399){
                    alert.error(`${phone} doesn't seem to be a valid phone number.  Please enter a valid number.`)
                    _errors.phone = true
                } else {
                    const phoneReg = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
                    _errors.phone = !phoneReg.test(phone)
                }
            }
        } else {
            _errors.phone = false
        }
        
        _errors.consent = !consent

        let hasError = reduce(_errors, (result, value, key) => {
            return result || value
        })
        setErrors({..._errors})

        return hasError
    }


    const _onSubmit = async (e) => {
        
        e.preventDefault()
        if(!embedded){
            if(state === 0){
                return false
            }
            if(state === 1){
                onCheck(e?.target?.email?.value)
                return
            }
        }
      


        if(await hasFormError(e.target)){
            return
        }
            const email = e.target.email.value
            const first = e.target.first_name.value
            const last = e.target.last_name.value
            const phone = e.target.phone.value
            const zip = e.target.zip.value
            const country = e.target.country.value         
        try{
            await UserManager.register(
                email,
                first,
                last,
                phone,
                zip,
                country,
                consent,
                !playable
            )

            alert.success("You're Registered")
            if(onSubmit){
                 onSubmit()
            } else {
                closeModal()
            }
            
        }catch(e) {
            console.error(e)
            alert.warning(e)
        }
    }


    return (
             <ModalContainer>
                {!embedded && <ModalHeader>
                    <h5 className="input-header-title">Register / Login for Revelation Today</h5>
                    <button type="button" className="close" onClick={onClose} aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </ModalHeader>}
                <Form onSubmit={_onSubmit}>
                    <LeftColumn>
                       <Label htmlFor="email">Email</Label>
                        <Input id="email" name="email" type="email" autoComplete="email" required={state === 2 || embedded} placeholder={"Your email"} hasError={errors?.email} value={originalEmail}/>
                    </LeftColumn>

                    <Collapse in={state === 2 || embedded} style={{width: '100%'}}>
                        <FormGroup>
                        <LeftColumn>
                           <Label htmlFor="first_name">First Name</Label>
                            <Input id="first_name" name="first_name" type="text" autoComplete="name" required={state === 2 || embedded} placeholder={"First Name"} hasError={errors?.first}/>
                        </LeftColumn>
                        <LeftColumn>
                           <Label htmlFor="last_name">Last Name</Label>
                            <Input id="last_name" name="last_name" type="text" autoComplete="name" required={state === 2 || embedded} placeholder={"Last Name"} hasError={errors?.last}/>
                        </LeftColumn>
                        <LeftColumn>
                           <Label htmlFor="zip">Zipcode</Label>
                            <Input id="zip" name="zip" type="text" autoComplete="zip" required={state === 2 || embedded} placeholder={"Zip / Postal Code"} hasError={errors?.zip}/>
                        </LeftColumn>
                        <LeftColumn>
                           <Label htmlFor="phone">Phone</Label>
                            <Input id="phone" name="phone" type="tel" autoComplete="tel" required={state === 2 || embedded} placeholder={"Your phone number"} hasError={errors?.phone}/>
                        </LeftColumn>
                        </FormGroup>
                    </Collapse>
                    <Collapse in={state === 2 || embedded} style={{width: '100%'}}>
                        <LeftColumn>
                            <Label className="input-title" htmlFor="country">Country</Label>
                            <CountrySelect />
                        </LeftColumn>
                    </Collapse>
         
                    <Collapse in={state === 2 || embedded} style={{width: '100%', marginTop: 20, backgroundColor: errors.consent ? "#FF000033" : "transparent", borderRadius: 5, padding: "5px 0px" }}>
                      <div className="form-disclaimer" >
                        <Checkbox
                            className={`form-checkbox`}
                            name="consent"
                            required={state === 2}
                            checked={consent}
                            style ={{
                                color: errors.consent ? "#FF0000" : "rgba(0, 0, 0, 0.54)",
                            }}
                            onChange={onConsentChange}
                            color="default"
                            // labelStyle={{color: 'white'}}

                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                       <Label onClick={onConsentChange}>
                            By submitting this form, I agree to receive reminders and updates about events from It Is Written to the email address and/or phone number provided above. <br/>
                            Message frequency varies. Message and data rates may apply.

                            Reply HELP to any message to receive help or STOP to cancel all further messages. View our <A href="https://www.itiswritten.com/terms-of-use/" target="_blank" >Terms of Service</A> and <A href="https://www.itiswritten.com/privacy-policy/" target="_blank">Privacy Policy</A>
                        </Label>

                        </div>
                    </Collapse>
                        <div className="modal-divider"></div>
                        <div className="modal-footer">
                            <div className="form-footer-container">
                                <div className="form-footer">
                                    <Submit type="submit"  value={"Next"} ></Submit>
                                    <Close onClick={onClose} >Close</Close>
                             </div>
                        </div>
                    <div style={{paddingBottom: state === 2 ? 150 : 0}}></div>
                </div>
                </Form>
            </ModalContainer>
    )
}




const CountrySelect = props => {
    return(
        <div>
            <div className="form-select-group">

            <Select className="input" name="country" id="country">
                <option defaultValue="selected" value="US">United States</option>
                <option disabled="disabled" value="---------------">---------------</option>
                {
                    map(COUNTRIES, country => {
                        return(
                            <option key={country.value} value={country.value}>{country.text}</option>
                        )
                    })
                }
            </Select>
            </div>
        </div>
    )
}
export const StateSelect = props => {

    const onChange = (e) => {
        props?.onChange?.(e)
    }


    return(
        <div>
            <div className="form-select-group">

            <Select className="input" name="state" id="state" onChange={onChange}>
                {/* <option defaultValue="selected" value="TN">Tennessee</option> */}
                <option disabled="disabled" selected value="---------------">---------------</option>
                {
                    map(STATES, state => {
                        return(
                            <option key={state.name} value={state.abbreviation}>{state.name}</option>
                        )
                    })
                }
            </Select>
            </div>
        </div>
    )
}