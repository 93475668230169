import { DataManagement} from './Database';
import { StorageManagement} from './Storage';



export const Storage = new StorageManagement();
export const Database = new DataManagement();




