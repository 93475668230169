
import React, {useContext, useEffect, useState, createContext} from 'react'

import { BehaviorSubject, Subject, takeUntil } from 'rxjs'

import _ from 'lodash';
import {  Storage , Database} from './exports';
import { createManagerInfo } from '../utilities';
import {  AUTHENTICATION_STATUS} from '../constants';

const { ClientJS } = require('clientjs');
export class UserManagement {
  authenticated = new BehaviorSubject(AUTHENTICATION_STATUS.unknown)
  activeUser = new BehaviorSubject()
  onLogout = new Subject()
  user = null;

  constructor(){
    this.managerName = createManagerInfo('USER_MANAGER', 1)  


    Storage.init()
    .then(async () =>{

      let status = await Storage.get("authed")
      let user = await Storage.get("user")
      
      if(user){
        this.activeUser.next(user)
      } else {
        this.activeUser.next(null)
      }

      if(!!status) {
        this.authenticated.next(status)
      } else {
        this.authenticated.next(AUTHENTICATION_STATUS.unauthenticated)
      }
    }).catch(console.error)
  }


  submitQuestion = async (text) => {

    let user = await this.getUserSnapshot()

    if(!user.id){
      return Promise.reject(new Error("No User"))
    }
    const body = {
      id: user.id,
      text,
      email: user.email
    }
    try{
      return Database.post('/askjohn', body)
    } catch(e) {
      if(typeof e?.err === typeof ""){
        return Promise.reject(new Error(e))
      } else {
        return Promise.reject()
      }
      
    }
      
  }

  getUserSubscription = () => this.activeUser.asObservable()

  checkValidProfile = async (email, isChatt) => {

    if(!email || email?.trim() === ""){
      return Promise.reject(new Error("No Email Provided"))
    }

    try{
      let result = await Database.get(`/attend?email=${email}&isChatt=${isChatt ? 1 : 0}`)
      Storage.set(result, "user")
      this.activeUser.next(result)
      Storage.set(AUTHENTICATION_STATUS.authenticated, "authed")
      this.authenticated.next(AUTHENTICATION_STATUS.authenticated)
      return result
    }catch(e) {
      console.log(e)
      if(e.status === 404){
        return false
      } else {
        return Promise.reject(e.error)
      }
      
    }
  }
  getLogoutNotifications = () => this.onLogout.asObservable()

  _saveAndDistributeUser = async (user) => {
    if(_.isEqual(this.activeUser.value, user)){
      return
    }
    this.activeUser.next(user)
  }


  _init = _.noop

   _updateConnectionStatus = (value) => {
    this._updateShouldLogin()
  }

  getAuthenticationStatus = () => this.authenticated.asObservable()
  getUserSnapshot = async () => {
    if(this.authenticated.value === AUTHENTICATION_STATUS.authenticated){
      let user = await Storage.get("user")
      return user
    } else {
      return null
    }
  }


  _logout = async () => {
    this.user = null
    this.activeUser.next(null)
 
    try{
      Storage.set(AUTHENTICATION_STATUS.unauthenticated, "authed")
      Storage.set(null, "user")
    } catch(e) {

    }

    this.onLogout.next(null)
    this.authenticated.next(AUTHENTICATION_STATUS.unauthenticated)
  }

  saveAddress = async (id, address) => {
    try{

      await Database.post('/rtaddress', {
        id,
        address
      })

      let user = await this.getUserSnapshot()
      user["has_address"] = true
      Storage.set(user, "user")
      this.activeUser.next(user)
      return true
    } catch(e) {
      return Promise.reject(e?.message)
    }


  }

  submitDecisionCard = async(survey, answers) => {
    for(let index in survey?.questions){
      let question = survey?.questions[index]
      question["answer"] = answers[index]
      survey.questions[index] = question
    }

    let user = await this.getUserSnapshot()

    const body = {
      contact_id: user.id,
      survey_id: survey?.id,
      responses: survey.questions
    }

    return Database.post('/dc', body)
  }
  
  logout = () => {
    this._logout()
  }

  getDevice = () => {
      const client = new ClientJS();
      return {
        // 'BrowserData': client.getBrowserData(),
        'Browser': client.getBrowser(),
        'fingerprint': `${client.getFingerprint()}`,
        'BrowserVersion': client.getBrowserVersion(),
        'Engine': client.getEngine(),
        'Device': client.getDevice(),
        'DeviceType': client.getDeviceType(),
        'CPU': client.getCPU(),
        'ScreenPrint': client.getScreenPrint(),
        'ColorDepth': client.getColorDepth(),
        'CurrentResolution': client.getCurrentResolution(),
        'AvailableResolution': client.getAvailableResolution(),
        'DeviceXDPI': client.getDeviceXDPI(),
        'DeviceYDPI': client.getDeviceYDPI(),
        'Plugins': client.getPlugins(),
        'isJava': client.isJava(),
        // 'JavaVersion': client.getJavaVersion(),
        'isFlash': client.isFlash(),
        // 'FlashVersion': client.getFlashVersion(),
        'isSilverlight': client.isSilverlight(),
        'SilverlightVersion': client.getSilverlightVersion(),
        'MimeTypes': client.getMimeTypes(),
        'isMimeTypes': client.isMimeTypes(),
        'isFont': client.isFont(),
        'Fonts': client.getFonts(),
        'isLocalStorage': client.isLocalStorage(),
        'isSessionStorage': client.isSessionStorage(),
        'isCookie': client.isCookie(),
        'TimeZone': client.getTimeZone(),
        'Language': client.getLanguage(),
        'SystemLanguage': client.getSystemLanguage(),
        'isCanvas': client.isCanvas()
    }
  }


  markAttended = async (event) => {
      // get the user
      let user = await this.getUserSnapshot()
      if(!user?.id){
        return 
      }
      const device = this.getDevice()
      let body = {
        device,
        contact:{
          id: user.id
        },
        events: [
          event
        ],
        isChatt: 0,
      }
      try{
        let response = await Database.post('/attend', body)
        console.log(response)
        return
      } catch(e) {
        console.error(e)
      }
      

  }
  
  register = async (email, first_name, last_name, phone, zip, country,consent, isChatt) => {
    const device = this.getDevice()
    let body = {
      device,
      contact:{
        email,
        first_name,
        last_name,
        phone, 
        zip,
        country,
        consent,
      },
      isChatt,
    }




    try{
      let response = await Database.post('/attend', body)
      const {id, first_name, last_name, email} = response
      Storage.set(AUTHENTICATION_STATUS.authenticated, "authed")
      Storage.set({id, first_name, last_name, email}, "user")
      let user = {}
      this._saveAndDistributeUser(user, true)
      this.authenticated.next(AUTHENTICATION_STATUS.authenticated)
      return user
      
    } catch (e) {
      console.error(e)
        return Promise.reject(e)      
    }

  }


}

const UserManager = new UserManagement()
const UserContext = createContext()

export const useAuth = () => useContext(UserContext)


export const UserProvider = ({children}) => {
  
  const [isLoggedIn, set] = useState(false)
  const [user, setUser] = useState()

  useEffect(() => {
    const leaving = new Subject()
    UserManager.getAuthenticationStatus()
    .pipe(takeUntil(leaving))
    .subscribe(value => {
      // localStorage.setItem("authed", AUTHENTICATION_STATUS.authenticated)
      set(value === AUTHENTICATION_STATUS.authenticated)
    })

    return () => leaving.next()
  })

  useEffect(() => {
    const leaving= new Subject()
    UserManager.getUserSubscription()
    .pipe(takeUntil(leaving))
    .subscribe(setUser)

    return () => leaving.next()
  })

  return(
    <UserContext.Provider value={{isLoggedIn, ...UserManager, user}}>
      {children}
    </UserContext.Provider>
  )
}

