import React, {useEffect, useState, createContext, useContext} from 'react'
import { Checkbox, Collapse, Drawer, FormControlLabel, Modal } from '@material-ui/core'
import { forEach, map } from 'lodash'
import styled from 'styled-components'
import { AuthModal, Input as _Input, Label, LeftColumn, StateSelect} from './Modal/Modal'
import { useAuth } from '../managers/UserManager'
import { useAlert } from '../managers/Alert'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    // height: 100vh;
    width: 100vw;
`

const Inner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: white;
    padding: 3vw;
    max-width: 90vw;
    @media (max-width: 600px){
        padding: 3vw 0;
    }

`

const Input = styled(_Input)`

    min-width: 300px;
    max-width: 90vw;
    margin-left: 0;

    @media (max-width: 600px){
        max-width: 90vw;
        width: auto;
    }
`

const ShortInput = styled(_Input)`

    min-width: 50px;
    margin-right: 10px;
    max-width: 90vw;

    @media (max-width: 600px){
        width: 50px;
    }
`


const RNButton = styled.button`
    font-family: 'Roboto', sans-serif;
    transition: all 0.2s ease-in-out;
    background-color: #FFD41C;
    padding: 15px 35px;
    font-weight: bold;
    color: black;
    border: none;
    font-size: 1em;
    border-radius: 4px;
    white-space: nowrap;
    @media (max-width: 1250px ){
        font-size: 0.7em;
    }
    `

const CloseButton = styled(RNButton)`
    background-color: #ccc;
    color: black;
`

const Horizontal = styled.div`
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    min-width: 300px;
`

const DecisionContext = createContext()

export const useDecisionCard = () => useContext(DecisionContext)
export const DecisionProvider = ({children}) => {

    const [isOpen, setOpen] = useState(false)
    const [survey, setSurvey] = useState()
    const openDecision = async (key = "") => {
        let id = decode[String(key).toLowerCase()]
        let res = await fetch(`https://outreach.iiw.org/api/v1/surveys/${id}`)
        // let res = await fetch(`https://dev01-nehemiah.iiw.us/api/v1/surveys/${id}`)
        let _survey = await res.json()
        setSurvey(_survey)
        setOpen(true)
    }

    const closeDecision = () => {
        setOpen(false)
    }

    return(
        <DecisionContext.Provider value={{isOpen, openDecision, closeDecision, survey}}>
            {children}
        </DecisionContext.Provider>
    )
}

const State = {
    noUser: "none",
    needAddress: "address",
    success: "success"
     
}

export function DecisionCard(){

    
    const [answers, setAnswers] = useState([])
    const {isOpen, closeDecision, survey} = useDecisionCard()
    const {user, saveAddress, checkValidProfile, submitDecisionCard} = useAuth()
    const alerts = useAlert()
    const [needsAuth, setNeedsAuth] = useState(false)
    const [email, setEmail] = useState("")
    const [address1, setAddress1] = useState("")
    const [address2, setAddress2] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")

    useEffect(() => {
        let answers = {}
        forEach(survey?.questions, q => {
            if(q.kind === 'bool'){
                answers[q.id] = false
            }else {
                answers[q.id] = "No Response"
            } 
        })

    },[])



    const check = async  (email) => {
        try{
            let response = await checkValidProfile(email, false)
            if(!response){
                setNeedsAuth(true)
            }
        } catch(e) {
            if(e.status === 404){
                setNeedsAuth(true)
            }
        }
    }   

    const updateAnswer = (index, value) => {
        let _answers = answers
        _answers[index] = value
        setAnswers(_answers)
    }

    const renderCheckbox = (q, i) => (
        <FormControlLabel
            control={<Checkbox
                key={i}
                checked={answers?.[i]}
                onChange={(e) => updateAnswer(i, e.target.checked)}
                name={q?.title}
                color="primary"
            />}
            label={q?.title}
        />
    )

    const renderEmailCheck = () => {

        const _check = () => {
            check(email)
        }

        const _setEmail = (e) => {
            setEmail(e.target.value)
        }

        const onAuth = () => {
            setNeedsAuth(false)
        }

        return(
            <Inner>
                <Collapse in={!needsAuth}>
                <Label>Please Enter Your Email</Label>
                <Input name="email" type="email" placeholder="Email" onChange={_setEmail} />
                <Horizontal>
                    <RNButton onClick={_check}>Next</RNButton>
                    <CloseButton onClick={closeDecision}>Cancel</CloseButton>
                </Horizontal>
                </Collapse>
                <Collapse in={needsAuth}>
                    <AuthModal onSubmit={onAuth} embedded={true} originalEmail={email} onClose={closeDecision}/>
                </Collapse>
            </Inner>
        )
    }

    const renderInput = (q, i) => {
        const onChange = (e) => updateAnswer(i, e?.target?.value)
        return <Input key={i} name={q?.title} placeholder={q?.title} onChange={onChange}/>
    }

    const renderAddressForm = () => {
        return (
            <>
                <Input name="address1" placeholder="Address" onChange={(e) => setAddress1(e?.target?.value)} required/>
                <br />
                <Input name="address2" placeholder="Address 2" onChange={(e) => setAddress2(e?.target?.value)}  />
                <Horizontal>
                    <ShortInput name="City" placeholder="City" onChange={(e) => setCity(e?.target?.value)}  required/>
                    <StateSelect onChange={(e) => setState(e?.target?.value)}  required/>
                </Horizontal>
            </>
           

        )
    }

    useEffect(() => {
        if(!user?.email || user?.email === ""){ return }
        const call = async () => {
            let response = await checkValidProfile(user?.email ,false )
        }
        call()
        
    }, [user?.email])

    const renderSurvey = () => {
        return(
            <Inner>
                <h1>{survey?.description}</h1>
                {map(survey?.questions, (q, i) => {
                    if(q.kind === 'bool'){
                        return renderCheckbox(q, i)
                    } else {
                        return renderInput(q, i)
                    }

                })}
                <br />
                {!!user && !user?.has_address && renderAddressForm()}
                <Horizontal>
                    <RNButton onClick={submit}>Submit</RNButton>
                    <CloseButton onClick={closeDecision}>Close</CloseButton>
                </Horizontal>
            </Inner>
        )
    }

    const submit = async() => {
        // save the address if you have an address to save

        if(!address1 || address1 === ""){
            alerts.error("State Required")
            return
        }

        if(!state || state === ""){
            alerts.error("State Required")
            return
        }
    
        if(!user?.id){
            alerts.error("Unknown Identification Error")
            return
        }
            try{
                // endpoint not working, getting 404
                await saveAddress(
                    user?.id, 
                    {
                        address1,
                        address2,
                        city,
                        state
                    } )
               await submitDecisionCard(
                    survey,
                    answers
                )

                closeDecision()
            } catch(e) {
                console.error(e)
            }

        // then submit the survey
        // need endpoint for this.

    }


    return(
        <Drawer open={isOpen} anchor="bottom" >
            <Container>
                    {!user && renderEmailCheck()}
                    {!!user && renderSurvey()}
            </Container>
        </Drawer>

    )
}


const decode = {
    "grace": "de441edf-0993-4458-8bc7-81f4c00fb24c",
    "truth": "296e9a32-3622-4338-ae8d-46857ae5ba1b" ,
    "stand" :"acf79cbb-c633-42a2-a122-e80fe1b39424",
    "renew" : "912048de-e11f-453b-83c6-2f043e73597a",
    "jesus" : "0318c3c3-3e86-4a87-ad63-61dc0e54eee7",
    "commit": "74a44d8a-5a3e-47a0-a5c0-9a64b5ca6779",
    "decide": "9d33b7b0-639e-4044-b41f-7debbdda52f5"
}

