import React from 'react';
import styled from 'styled-components'
import {OnlyDesktop, OnlyMobile } from '../pages/home';
import { Chevron } from './chevrons';


const yellow = "#F9CF19"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    @media (max-width: 600px){
        margin: 4vw 0;;
    }

`

const Title = styled.span`
    font-family: 'Roboto', sans-serif;
    font-size: 2.7vw;
    font-weight: 500;
    color: ${yellow};
    text-transform: uppercase;
    margin-bottom: 1vw;
    @media (max-width: 600px){
        font-size: 6vw;
    }
`

const LineContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 4vw;

`

const Line = styled.span`
    font-family: 'Archivo Narrow', sans-serif;
    font-size: 2.7vw;
    color: white;
    font-weight: bold;
    margin-left: 2vw;
    @media (max-width: 600px){
        font-size: 5.5vw;
        margin-top: 1vw;
    }
  
`
export function Topics(){

    return(
        <Container>
            <Title>Topics Include</Title>
        <LineContainer>
               <OnlyDesktop><Chevron role="presentation" width={1} color={yellow} flipped/></OnlyDesktop>
               <OnlyMobile><Chevron role="presentation"  width={3} color={yellow} flipped/></OnlyMobile>
                <Line>The Great Reset</Line>
            </LineContainer>
            <LineContainer>
               <OnlyDesktop><Chevron role="presentation"  width={1} color={yellow} flipped/></OnlyDesktop>
               <OnlyMobile><Chevron role="presentation"  width={3} color={yellow} flipped/></OnlyMobile>
                <Line>Charmed by the Supernatural</Line>
            </LineContainer>
            <LineContainer>
               <OnlyDesktop><Chevron role="presentation"  width={1} color={yellow} flipped/> </OnlyDesktop>
               <OnlyMobile><Chevron role="presentation"  width={3} color={yellow} flipped/> </OnlyMobile>
                <Line>The Global Power Shift</Line>
            </LineContainer>
            <LineContainer>
               <OnlyDesktop><Chevron role="presentation"  width={1} color={yellow} flipped/></OnlyDesktop>
               <OnlyMobile><Chevron role="presentation"  width={3} color={yellow} flipped/></OnlyMobile>
                <Line>The Mark of the Beast</Line>
            </LineContainer>

        </Container>
    )
}