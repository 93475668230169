import * as React from 'react';
import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import videojs from 'video.js';
import VREPlayer from 'videojs-react-enhanced';
import 'video.js/dist/video-js.css';
import { Collapse } from '@material-ui/core';
import { useVideo } from './video';


export const VideoPlayer = ({video, autoplay = null, poster = ""}) => {
    const [options, setOptions] = useState(
        {
            src: null,
            controls: true, 
            // autoplay: "play",
            
        }
    )

    const {setPlayState} = useVideo()
    
    const [width, setWidth] = useState(0)
    const leaving = new Subject()

    function debounce(fn, ms) {
        let timer
        return _ => {
          clearTimeout(timer)
          timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
          }, ms)
        };
      }
    const update = () => {

    }

    useEffect(() => {
        let div = document.createElement("div")

        div.style="width: 0; height: 0; border-top: 2vw solid transparent; border-left: 3vw solid white; border-bottom: 2vw solid transparent; margin-left: 3.5vw;"

        let el = document.getElementsByClassName("vjs-big-play-button")[0]
        if(!el){ return }
        for(let node of el.childNodes) {
            el.removeChild(node)
        }

        el.style.width = '10vw'
        el.style.height = "10vw"
        el.style.borderRadius = "50%"
        el.style.position = "absolute"
        el.style.left = "calc(55% - 10vw)"
        el.style.top = "calc(60% - 10vw"
        
        for(let node of el.childNodes) {
            el.removeChild(node)
        }
        el.appendChild(div)
    }, [width, video, update])


    

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
          setWidth(window.innerWidth)
        }, 1000)
    
        window.addEventListener('resize', debouncedHandleResize)
    
        return () =>  window.removeEventListener('resize', debouncedHandleResize)
        
    })

    useEffect( async ()=> {
        
        try{

    
            setOptions({...options, src: video.video, autoplay})

        } catch(e) {
            console.error(e)
        }
    
        return () => leaving.next()
    }, [video])

    const videojsOptions = {
      fluid: true,
    };



    return(
        <div style={{width: "100%"}}>
        
            {!!options.src && <VREPlayer
                playerOptions={options}
                resources={{
                    poster: video?.poster
                }}
                onLoadedData={update}
                videojsOptions={videojsOptions}
                onError={console.error}
                onPlaying={setPlayState}
                onPause={setPlayState}
                onEnded={setPlayState}
            />
            }
        </div>

    )
}

// interface IPlayerOptions {
//     autoplay?: 'muted' | 'play' | 'any';
//     controls?: boolean;
//     height?: number;
//     loop?: boolean;
//     muted?: boolean;
//     playsinline?: boolean;
//     preload?: 'auto' | 'metadata' | 'none';
//     src?: string;
//     width?: number;
// }