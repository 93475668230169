import React, { useEffect } from 'react';
import { Footer } from './footer';
import {Modal} from '../components/Modal/Modal'
import { useLocation, useHistory } from 'react-router-dom';
import { DecisionCard, useDecisionCard } from './decision-card';
import {includes, replace} from 'lodash';
import { AskJohnAQuestion } from './AskJohnAQuestion';

export function Layout({ children}){

    const {isOpen, openDecision, closeDecision} = useDecisionCard()
    const location = useLocation()
    const history = useHistory()
    useEffect(() => {
        if(includes(location.pathname, '/response')){
         let key = replace(location.pathname, "/response/" , "")
          openDecision(key)
          history.push('/')
        }
  })
    return(
            <div>
                <Modal />
                <AskJohnAQuestion />
                <DecisionCard />
                {children}
                <Footer />
            </div>
    )
}